<app-header></app-header>
<!-- Ballerina Section -->
<section id="Ballerina" class="section Ballerina">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="Ballerina-content">
          <h4>Kvalita Made In Germany</h4>
          <p align="justify">
            Vysokokvalitné kuchyne navrhnuté podľa vašich predstáv s inovatívnym
            a revolučným dizajnom. Najnovšie trendové kuchynské motívy, od
            revolučných vzorov až po vzrušujúce vybavenie a interiérové
            koncepty.
          </p>
          <p align="justify">
            Ballerina prejavuje svoju predstavu o dizajne kuchyne. Nie je
            náhodou, že spoločnosť Ballerina-Küchen bola zvolená za jednu z
            najobľúbenejších značiek a víťaza Customer Choice 2017.
          </p>
          <p align="justify">
            Tešíme sa na Vašu návštevu v kuchynskom štúdiu RETRY - BALLERINA
            KUCHYNE na adrese
            <a
              href="https://www.google.sk/maps/place/Z%C3%A1hradn%C3%A1+19,+902+01+Pezinok/@48.2893023,17.269489,19z/data=!3m1!4b1!4m5!3m4!1s0x476c90c7e95a3425:0x38fabb954d52baaa!8m2!3d48.2893014!4d17.2700362"
              >Záhradná 19, Pezinok</a
            >, kde si môžete pozrieť vystavené kuchyne a rôzne dekoračné vzorky.
          </p>
          <p align="justify">
            Pripravíme pre Vás zdarma 3D vizualizáciu Vašej kuchyne. Predstavíme
            a predvedieme Vám aj vystavené funkčné kuchynské spotrebiče do Vašej
            kuchyne. V ponuke sú značky SIEMENS - vystavená v showroome je
            edícia studioLine, ďalej BOSCH, BORA , AEG, ELECTROLUX, MIELE ,
            LIEBHERR a iné. Radi Vás spoznáme osobne, alebo nás neváhajte
            kontaktovať na telefónnom čísle
            <a href="tel:+421 903 200 607">+421 903 200 607</a> alebo mailom na
            adrese
            <a href="mailto:juraj.gago@gmail.com">juraj.gago@gmail.com</a>.
          </p>
        </div>
      </div>
      <div class="col-md-3 col-sm-3">
        <div class="Ballerina-content">
          <h5>Kuchyne Ballerina</h5>
          <br />
          <ul>
            <li><a [routerLink]="['/moderne']">Moderné</a></li>
            <li><a [routerLink]="['/country']">Country-house</a></li>
            <li><a [routerLink]="['/classic']">Klasické</a></li>
            <li><a [routerLink]="['/confort']">Komfortné</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-3">
        <div class="Ballerina-content">
          <br />
          <br />
          <br />
          <br />
          <ul>
            <li><a [routerLink]="['/robus']">Robusné kuchyne</a></li>
            <li><a [routerLink]="['/line45']">Line 45</a></li>
            <li><a [routerLink]="['/blackline']">BlackLine</a></li>
            <li><a [routerLink]="['/ykitchen']">Y kuchyňa</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Ballerina Section -->
<!-- portfolio grid section -->
<app-portfolio></app-portfolio>
<!-- portfolio grid section -->
<app-googlestreet></app-googlestreet>
