import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-classic',
  templateUrl: '../gallerybase.html',
})
export class ClassicComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'Frame_3358', "images/Kitchen/Classic Kitchens/Frame_3358_1.jpg");
    this.setImages(1, 'Highline_7246', "images/Kitchen/Classic Kitchens/Highline_7246_1.jpg");
    this.setImages(2, 'Highline_7250', "images/Kitchen/Classic Kitchens/Highline_7250_1.jpg");
    this.setImages(3, 'Highline_7298', "images/Kitchen/Classic Kitchens/Highline_7298_1.jpg");
    this.setImages(4, 'Opal- H_5650', "images/Kitchen/Classic Kitchens/Opal- H_5650_1.jpg");
    this.setImages(5, 'Opal M_5450', "images/Kitchen/Classic Kitchens/Opal M_5450_1.jpg");
    this.setImages(6, 'Roma S_3732', "images/Kitchen/Classic Kitchens/Roma S_3732_1.jpg");
    this.setImages(7, 'Sky_3450', "images/Kitchen/Classic Kitchens/Sky_3450_1.jpg");
    this.setImages(8, 'Sky_3496', "images/Kitchen/Classic Kitchens/Sky_3496_1.jpg");
    this.setImages(9, 'Top_1251', "images/Kitchen/Classic Kitchens/Top_1251_1.png");
    this.setImages(10, 'Top_1456', "images/Kitchen/Classic Kitchens/Top_1456_1.jpg");
    this.setImages(11, 'Top_1466', "images/Kitchen/Classic Kitchens/Top_1466_1.jpg");
    this.setImages(12, 'Frame_3364', "images/Kitchen/Classic Kitchens/Frame_3364_1.jpg");
    this.setImages(13, 'TOP_1496', "images/Kitchen/Classic Kitchens/TOP_1496_1.jpg");
    this.setImages(14, 'Montana_7710', "images/Kitchen/Classic Kitchens/MONTANA_7710_.jpg");
    this.setImages(15, 'TOP_PUR', "images/Kitchen/Classic Kitchens/TOP__PUR_.jpg");
    this.setImages(16, 'TOP_1026', "images/Kitchen/Classic Kitchens/TOP_1026_Lampe.jpg");
    this.setImages(17, 'TOP_1406', "images/Kitchen/Classic Kitchens/TOP_1406.jpg");
    this.setImages(18, 'TOP_1406_det', "images/Kitchen/Classic Kitchens/TOP_1406_D.jpg");
    this.setImages(19, 'TOP_1421', "images/Kitchen/Classic Kitchens/TOP_1421.jpg");
    this.setImages(20, 'TOP_1421_det', "images/Kitchen/Classic Kitchens/TOP_1421_D5.jpg");
    this.setImages(21, 'TOP_1466', "images/Kitchen/Classic Kitchens/TOP_1466.jpg");
    this.setImages(22, 'TOP_1466', "images/Kitchen/Classic Kitchens/TOP_1466_.jpg");
    this.setImages(23, 'TOP_1466_det', "images/Kitchen/Classic Kitchens/TOP_1466_D2.jpg");
    this.setImages(24, 'TOP_1496_PUR_2641', "images/Kitchen/Classic Kitchens/TOP_1496_PUR_2641.jpg");
    this.setImages(25, 'TOP_1496_PUR_2641', "images/Kitchen/Classic Kitchens/TOP_1496_PUR_2641_.jpg");
    this.setImages(26, 'Sky_3450', "images/Kitchen/Classic Kitchens/SKY_3450.jpg");
    this.setImages(27, 'Sky_3450_det', "images/Kitchen/Classic Kitchens/SKY_3450_ DET.jpg");
    this.setImages(28, 'TOP_1331', "images/Kitchen/Classic Kitchens/TOP_1331.jpg");
    this.setImages(29, 'TOP_1331_det', "images/Kitchen/Classic Kitchens/TOP_1331_D.jpg");
    this.setImages(30, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA1.jpg");
    this.setImages(31, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA2.jpg");
    this.setImages(32, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA3.jpg");
    this.setImages(33, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA6.jpg");
    this.setImages(34, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA7.jpg");
    this.setImages(35, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA8.jpg");
    this.setImages(36, 'VÝBAVA', "images/Kitchen/Classic Kitchens/VÝBAVA9.jpg"); 

  }
}
