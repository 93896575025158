import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-HB478GUB0",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class HB478GUB0Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "HB836GVB6";
    this._imagePath =
      "images/siemens//hb478gub0/mcsa01974724-hb478gcb0s-siemens-stp-fullsizeoven--eox5-def.jpg";
    this._headerText =
      "Skráťte si čas varenia vďaka funkcii coolStart, rýchlemu predohrevu a activeClean.";
    this._productPrice = 1059;
    this._productDesc = "../../../ProductDesc/HB478GUB0.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/hb478gub0/01.jpg");    
      this.setImages(1, '', "images/siemens/hb478gub0/02.jpg");    
      this.setImages(2, '', "images/siemens/hb478gub0/03.jpg");
      this.setImages(3, '', "images/siemens/hb478gub0/04.jpg");
      this.setImages(4, '', "images/siemens/hb478gub0/05.jpg");
      this.setImages(5, '', "images/siemens/hb478gub0/06.jpg");
      this.setImages(6, '', "images/siemens/hb478gub0/07.jpg");
      this.setImages(7, '', "images/siemens/hb478gub0/08.jpg");
  }
}
