import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-moderne',
  templateUrl: '../gallerybase.html'
})
export class ModerneComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'Arte-B_3389', "images/Kitchen/Modern kitchens/Arte-B_3389_1.jpg");
    this.setImages(1, 'Fenix_4901', "images/Kitchen/Modern kitchens/Fenix_4901_1.jpg");
    this.setImages(2, 'Opal H_5663', "images/Kitchen/Modern kitchens/Opal H_5663_1.jpg");
    this.setImages(3, 'Opal_5699 in Gelb', "images/Kitchen/Modern kitchens/Opal_5699 in Gelb_1.jpg");
    this.setImages(4, 'Opal_5699 in Rot', "images/Kitchen/Modern kitchens/Opal_5699 in Rot_1.jpg");
    this.setImages(5, 'Resopal_3431', "images/Kitchen/Modern kitchens/Resopal_3431_1.jpg");
    this.setImages(6, 'Resopal_3436', "images/Kitchen/Modern kitchens/Resopal_3436_1.jpg");
    this.setImages(7, 'Sherwood_2036', "images/Kitchen/Modern kitchens/Sherwood_2036_1.png");
    this.setImages(8, 'Smart_4031', "images/Kitchen/Modern kitchens/Smart_4031_1.jpg");
    this.setImages(9, 'Top_1211', "images/Kitchen/Modern kitchens/Top_1211_1.jpg");
    this.setImages(10, 'Top_1276', "images/Kitchen/Modern kitchens/Top_1276_1.png");
    this.setImages(11, 'Top_1331', "images/Kitchen/Modern kitchens/Top_1331_1.png");
    this.setImages(12, 'Top_1351', "images/Kitchen/Modern kitchens/Top_1351_1.png");
    this.setImages(13, 'Top_1421', "images/Kitchen/Modern kitchens/Top_1421_1.jpg");
    this.setImages(14, 'Top_1461', "images/Kitchen/Modern kitchens/Top_1461_1.jpg");
    this.setImages(15, 'Top_1481', "images/Kitchen/Modern kitchens/Top_1481_1.jpg");
    this.setImages(16, 'Edition_2716', "images/Kitchen/Modern kitchens/EDITION_2716_1.jpg");
    this.setImages(17, 'Profi Line_4450', "images/Kitchen/Modern kitchens/Profi Line_4450_1.jpg");
    this.setImages(18, 'Resopal Inox_4054', "images/Kitchen/Modern kitchens/Resopal Inox_4054_1.jpg");
    this.setImages(19, 'DETAIL_XL_MONTANA 7710', "images/Kitchen/Modern kitchens/DETAIL_XL_MONTANA 7710.jpg");
    this.setImages(20, 'XL_1461', "images/Kitchen/Modern kitchens/XL_1461.jpg");
    this.setImages(21, 'XL_1466', "images/Kitchen/Modern kitchens/XL_1466.jpg");
    this.setImages(22, 'XL_1481', "images/Kitchen/Modern kitchens/XL_1481.jpg");
    this.setImages(23, 'XL_2061', "images/Kitchen/Modern kitchens/XL_2061.jpg");
    this.setImages(24, 'XL_EDITION_2706_EDITION_2726_D3_4c', "images/Kitchen/Modern kitchens/XL_EDITION_2706_EDITION_2726_D3_4c.jpg");
    this.setImages(25, 'XL_PURE_2061', "images/Kitchen/Modern kitchens/XL_PURE_2061.jpg");
    this.setImages(26, 'XL_SMART_4031_', "images/Kitchen/Modern kitchens/XL_SMART_4031_.jpg");
    this.setImages(27, 'XL_SMART_4031', "images/Kitchen/Modern kitchens/XL_SMART_4031.jpg");
    this.setImages(28, 'XL_TOP_1456', "images/Kitchen/Modern kitchens/XL_TOP_1456.jpg");
    
  }

}
