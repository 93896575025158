import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-EX807LYV1E",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class EX807LYV1EComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "EX807LYV1E";
    this._imagePath =
      "images/siemens/ex807lyv1e/mcsa01926230-ex807lyv1e-electrichob-siemens-stp-def.jpg";
    this._headerText =
      "Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení.";
    this._productPrice = 1249;
    this._productDesc = "../../../ProductDesc/EX807LYV1E.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/ex807lyv1e/01.jpg");    
      this.setImages(1, '', "images/siemens/ex807lyv1e/02.jpg");    
      this.setImages(2, '', "images/siemens/ex807lyv1e/03.jpg");
      this.setImages(3, '', "images/siemens/ex807lyv1e/04.jpg");
      this.setImages(4, '', "images/siemens/ex807lyv1e/05.jpg");
      this.setImages(5, '', "images/siemens/ex807lyv1e/06.jpg");
      this.setImages(6, '', "images/siemens/ex807lyv1e/07.jpg");
      this.setImages(7, '', "images/siemens/ex807lyv1e/08.jpg");
  }
}
