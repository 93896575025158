<!-- Slider Section -->
<section id="header-slider" class="section">
  <div id="myCarousel" class="carousel slide" data-ride="carousel"> 
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
       <li data-target="#myCarousel" data-slide-to="1"></li>
       <li data-target="#myCarousel" data-slide-to="2"></li>
       <li data-target="#myCarousel" data-slide-to="3"></li>
    </ol>
    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
      <div class="item {{showActiveClassIn1}}">
         <img src="images/Sources/banner1_text.png" alt="Chania">
      </div>
      <div class="item">
         <img src="images/Sources/banner2_text.png" alt="Chania">
      </div>
      <div class="item">
         <img src="images/Sources/banner3_text.png" alt="Chania">
      </div>
      <div class="item {{showActiveClassIn4}}">
         <img src="images/Sources/studioLine_1720x500_SK.JPG" alt="Chania">
      </div>
   </div>
    <!-- Controls --> 
  <!--<a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev"> <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span> <span class="sr-only">Previous</span></a>
  <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next"> <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span> <span class="sr-only">Next</span></a>-->
  </div>
</section>