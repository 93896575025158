import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-HB836GVB6",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class HB836GVB6Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "HB836GVB6";
    this._imagePath =
      "images/siemens/hb836gvb6/mcsa01934796-1341455-hb836gvb6-def.jpg";
    this._headerText =
      "Skráťte si čas varenia pomocou funkcie predohrevu.Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect.";
    this._productPrice = 1059;
    this._productDesc = "../../../ProductDesc/HB836GVB6.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/hb836gvb6/01.jpg");    
      this.setImages(1, '', "images/siemens/hb836gvb6/02.jpg");    
      this.setImages(2, '', "images/siemens/hb836gvb6/03.jpg");
      this.setImages(3, '', "images/siemens/hb836gvb6/04.jpg");
      this.setImages(4, '', "images/siemens/hb836gvb6/05.jpg");
      this.setImages(5, '', "images/siemens/hb836gvb6/06.jpg");
  }
}
