import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-BV830ENB1",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class BV830ENB1Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "BV830ENB1";
    this._imagePath =
      "images/siemens/bv830enb1/mcsa02050992-bv830enb1-vakuumierschublade-siemens-pga1-def.jpg";
    this._headerText =
      "Vákuovacia zásuvka: pre dlhotrvajúci pôžitok z chuti.";
    this._productPrice = 2259;
    this._productDesc = "../../../ProductDesc/BV830ENB1.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/bv830enb1/01.jpg");    
      this.setImages(1, '', "images/siemens/bv830enb1/02.jpg");    
      this.setImages(2, '', "images/siemens/bv830enb1/03.jpg");    
      this.setImages(3, '', "images/siemens/bv830enb1/04.jpg");    

  }
}
