<section class="tophead" role="tophead">
  <!-- Navigation Section -->
  <!--https://codepen.io/emredenx/pen/ojcxl -->
  <header id="header">
    <div class="header-content clearfix">
      <nav id="cssmenu">
        <div class="logo">
          <img src="images/Sources/ballerina_logo.svg" alt="logo" />
        </div>
        <div id="head-mobile"></div>
        <div class="button"></div>
        <ul class="ul-menu">
          <li><a href="#intro">ÚVOD</a></li>
          <li><a [routerLink]="['/moderne']">KUCHYNE</a></li>
          <!-- <li>
            <a href="#studiolinepreview">SIEMENS</a>
            <ul>
              <li><a [routerLink]="['/hc-siemens']">HOME CONNECT</a></li>
              <li><a [routerLink]="['/studioline']">PRODUKTY</a></li>
            </ul>
          </li> 
          <li>
            <a [routerLink]="['/bosch']">BOSCH</a>
            <ul>
              <li><a [routerLink]="['/hc-bosch']">HOME CONNECT</a></li>
            </ul>
          </li>-->
          <li><a [routerLink]="['/bora']">BORA</a></li>
          <li><a [routerLink]="['/nastiahnutie']">NA STIAHNUTIE</a></li>
          <li><a href="#contact">KONTAKT</a></li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- Navigation Section -->
</section>
<router-outlet></router-outlet>
<!-- footer section -->
<footer id="contact" class="footer">
  <div class="container-fluid">
    <div class="col-md-2 left">
      <h4>TELEFÓN</h4>
      <p>+421 903 200 607</p>
    </div>
    <div class="col-md-2 left">
      <h4>EMAIL</h4>
      <p>
        <a href="mailto:juraj.gago@gmail.com">juraj.gago@gmail.com</a><br />
      </p>
    </div>

    <div class="col-md-2 left">
      <!--<h4></h4>
       <p><img src="images/Sources/fb.png" /><a href="https://www.facebook.com/ballerinakuchyne.sk/">&nbsp;&nbsp;&nbsp;Facebook</a></p>-->
    </div>
    <div class="col-md-6 right">
      <br />
      <div class="retry-logo">
        <table>
          <tr>
            <td><img src="images/Sources/retry_logo.svg" /></td>
            <td><p class="page-year">&nbsp;s.r.o. © 2018</p></td>
          </tr>
          <tr>
            <td>
              <a target="_blank" href="https://www.madeingermany.sk"
                >www.madeingermany.sk</a
              >
            </td>
            <td rowspan="2" class="facebookcenter">
              <a
                href="https://www.facebook.com/ballerinakuchyne.sk/"
                target="_blank"
                ><img src="images/Sources/fb.png"
              /></a>
            </td>
          </tr>
          <tr>
            <td>
              <a target="_blank" href="https://www.kupelneriho.sk/"
                >www.kupelneriho.sk</a
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</footer>
<!-- footer section -->
