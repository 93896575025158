<app-header></app-header>
<section id="portfolio">
  <div class="row">
    <div class="col-md-12">
      <h3>Neobyčajný život vyžaduje neobyčajnú kuchyňu.</h3>
      <p class="text-justify">
        StudioLine sa vyznačuje výnimočnými prvkami, ktoré sú dostupné iba v
        tejto
        <a [routerLink]="['/studioline']"
          >exkluzívnej sérii vstavaných spotrebičov</a
        >. Moderné a vysokokvalitné vstavané spotrebiče studioLine sa môžu
        pochváliť inovatívnou technológiou a nezameniteľným dizajnom, ktorý
        úplne ohromí svojím maximálnym výkonom a komfortom. Aby bol každodenný
        život čo najjednoduchší a zažili ste čo najviac neobyčajných momentov –
        to je cieľom studioLine. A vďaka svojej inovatívnej technológii sú naše
        vstavané spotrebiče také inteligentné, že dokážu zostať v súlade s týmto
        tvrdením. Objavte, ako vám domáce spotrebiče Siemens pomôžu ušetriť viac
        času než kedykoľvek predtým.
      </p>
    </div>
    <div class="col-md-9">
      <img
        class="img-responsive"
        src="images/studiolinepreview/MCIM02382405_Siemens_REU_Campaign_studioLine_17_16_9.jpg"
      />
    </div>
    <div class="col-md-3">
      <h3>Exkluzívny rad zabudovaných spotrebičov pre najnáročnejších</h3>
      <p class="text-justify">
        Exkluzívny rad zabudovaných spotrebičov pre najnáročnejších Jedinečný
        rad zabudovaných spotrebičov studioLine značky Siemens, obohatí nielen
        vašu kuchyňu, ale aj váš život. Exkluzívny design a inovatívna výbava
        spotrebičov ponúka nezameniteľný pôžitok nielen na pohľad, ale navyše aj
        absolútnu voľnosť pri všetkom, čo vám život prináša. Nechajte sa uniesť
        dobrodružstvom a zažite ešte viac výnimočných momentov – vďaka veľmi
        výkonným domácim spotrebičom máte k dispozícii čas celého sveta.
        Exkluzivita je u studioLine zaručená. Pre vás to znamená nekompromisnú
        kvalitu, špičkové poradenstvo a nadštandardný servis.
      </p>
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_induction-hob.jpg"
      />
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <div class="row">
    <div class="col-md-12">
      <h3>Všetko výnimočné – zabudované rúry studioLine.</h3>
      <p class="text-justify">
        Požiadavka na rúry studioLine je jednoduchá – všetko najlepšie.
        Inovatívne technológie na pečenie, vysoko kvalitné materiály a dokonalé
        spracovanie. Všetko môžete vidieť, cítiť, ale predovšetkým kombinovať
        presne podľa svojich požiadaviek. Vďaka zjednotenému designu bude všetko
        skvele ladiť a pôsobiť celistvým dojmom. Nemusíte sa teda obmedzovať a
        môžete prispôsobiť štýl vašej kuchyne presne podľa seba.
      </p>
    </div>
    <div class="col-md-3 bottom">
      <h3>Jedinečný design. Exkluzivita v každom detaile.</h3>
      <p class="text-justify">
        Pre spotrebiče studioLine s dokonale zladeným designom je
        charakteristické priamočiare vedenie linky s architektonickou jasnosťou
        a najmodernejšou technikou spracovania materiálov. Funkčnosť designu je
        jedinečná: všetky spotrebiče je možné medzi sebou perfektne kombinovať.
        Vedľa seba alebo nad seba – vždy vznikne harmonický celistvý vzhľad. Za
        svoj exkluzívny a premyslený design boli zabudované spotrebiče
        studioLine vyznamenané renomovanými cenami za design.
      </p>
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_range-photo.jpg"
      />
    </div>
    <div class="col-md-9 bottom">
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_ovens1.jpg"
      />
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <div class="row">
    <div class="col-md-12">
      <h3>Haute Couture vo vašej kuchyni.</h3>
      <p class="text-justify">
        Nepodriaďujte sa pri varení varnej doske. Varné dosky radu studioLine
        umožňujú novú voľnosť pri varení. Užívajte si technológiu celoplošnej
        indukcie s automatickým rozpoznaním pozície, veľkosti a tvaru nádob. Pre
        intuitívne ovládanie a prehľad o aktuálnom nastavení dosky vám bude
        skvele slúžiť farebný TFT-dotykový displej. Perfektné riešenie pre
        modernú a otvorenú kuchyňu vám ponúka varná doska s integrovaným
        odsávačom.
      </p>
      <h3>Varenie na najvyššej úrovni - funkcia Sous-vide.</h3>
      <p class="text-justify">
        Profesionálne varenie nebolo nikdy také jednoduché. Nová funkcia
        Sous-vide prináša do vášho domova profesionálne varenie. Jedná sa o
        pečenie vo vákuu pri nízkej teplote. Táto jedinečná metóda varenia šetrí
        vitamíny a prináša jedinečný chuťový zážitok.
      </p>
    </div>
    <div class="col-md-9">
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_Backofen_5.jpg"
      />
    </div>
    <div class="col-md-3">
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_Backofen_4.jpg"
      />
      <br />
      <br />
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_Backofen_2.jpg"
      />
      <br />
      <br />
      <img
        class="img-responsive"
        src="images/studiolinepreview/studioLine_vacuum_sous_vide.jpg"
      />
    </div>
  </div>
</section>
