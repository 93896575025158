import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-robus',
  templateUrl: '../gallerybase.html'
})
export class RobusComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'Highline_7246', "images/Kitchen/Extremely Robust kitchens/Highline_7246_1.jpg");    
    this.setImages(1, 'Highline_7250', "images/Kitchen/Extremely Robust kitchens/Highline_7250_1.jpg");    
    this.setImages(2, 'Highline_7298', "images/Kitchen/Extremely Robust kitchens/Highline_7298_1.jpg");    
    this.setImages(3, 'Resopal Inox_4054', "images/Kitchen/Extremely Robust kitchens/Resopal Inox_4054_1.jpg");    
    this.setImages(4, 'Resopal_3416', "images/Kitchen/Extremely Robust kitchens/Resopal_3416_1.jpg");    
    this.setImages(5, 'Resopal_3431', "images/Kitchen/Extremely Robust kitchens/Resopal_3431_1.jpg");    
    this.setImages(6, 'Resopal_3436', "images/Kitchen/Extremely Robust kitchens/Resopal_3436_1.jpg");    
    this.setImages(7, 'Resopal_3821', "images/Kitchen/Extremely Robust kitchens/Resopal_3821_1.jpg");    
    this.setImages(8, 'Smart_4031', "images/Kitchen/Extremely Robust kitchens/Smart_4031_1.jpg");
    this.setImages(9, 'Smart_4041', "images/Kitchen/Extremely Robust kitchens/SMART_4041_1.jpg");     
    this.setImages(10, 'TOP_1496', "images/Kitchen/Extremely Robust kitchens/TOP_1496_1.jpg");    
    this.setImages(11, 'ALU SOKL', "images/Kitchen/Extremely Robust kitchens/ALU SOKL.jpg");
    this.setImages(12, 'RESOPAL_1411_P1_Model_110', "images/Kitchen/Extremely Robust kitchens/RESOPAL_1411_P1_Model_110.jpg");
    this.setImages(13, 'RESOPAL_3431', "images/Kitchen/Extremely Robust kitchens/RESOPAL_3431_.jpg");
    this.setImages(14, 'RESOPAL_3431', "images/Kitchen/Extremely Robust kitchens/RESOPAL_3431.jpg");
    this.setImages(15, 'RESOPAL_3436', "images/Kitchen/Extremely Robust kitchens/RESOPAL_3436.jpg");
    this.setImages(16, 'Resopal_3821', "images/Kitchen/Extremely Robust kitchens/RESOPAL_3821.jpg");
    this.setImages(17, 'Resopal', "images/Kitchen/Extremely Robust kitchens/RESOPAL.jpg");
    this.setImages(18, 'SMARTGLAS_4031', "images/Kitchen/Extremely Robust kitchens/SMARTGLAS_4031.jpg");
    this.setImages(19, 'TOP_1411', "images/Kitchen/Extremely Robust kitchens/TOP_1411_.jpg");
    this.setImages(20, 'BŘIDLICE.jpg', "images/Kitchen/Extremely Robust kitchens/BŘIDLICE.jpg");
    this.setImages(21, 'PROFILINE GLAS_4450', "images/Kitchen/Extremely Robust kitchens/PROFILINE GLAS_4450.jpg");
    this.setImages(22, 'HIGHLINE GLAS_7298_6200', "images/Kitchen/Extremely Robust kitchens/HIGHLINE GLAS_7298_6200.jpg");
    this.setImages(23, 'HIGHLINE_7246_', "images/Kitchen/Extremely Robust kitchens/HIGHLINE_7246_.jpg");
    this.setImages(24, 'HIGHLINE GLAS_7246', "images/Kitchen/Extremely Robust kitchens/HIGHLINE GLAS_7246.jpg");    
    this.setImages(25, 'HIGHLINE_7298', "images/Kitchen/Extremely Robust kitchens/HIGHLINE_7298.jpg");
    this.setImages(26, 'RITZENHOFF', "images/Kitchen/Extremely Robust kitchens/RITZENHOFF.jpg");
    
  }
}
