<section id="portfolio">
  <div class="row">
    <div class="col-md-12">
      <img src="images/Bosch/Bosch_sk.png" class="img-responsive" />
    </div>
  </div>
  <div class="col-md-12">
    <h4 class="text-center">
      Home Connect: domáce spotrebiče s chytrým ovládaním pre jednoduchší život
    </h4>
    <br />
    <br />
  </div>
  <div class="col-md-12">
    <p class="text-justify">
      Technológia Home Connect prináša do domácnosti úplne nové možnosti. Aj keď
      nie ste práve doma, môžete pohodlne kontrolovať a ovládať svoje spotrebiče
      na diaľku s pomocou svojho mobilného telefónu alebo tabletu. Špeciálna
      aplikácia ale neslúži len ako diaľkové ovládanie. Obsahuje tiež veľa
      praktických rád a tipov na mieru daného spotrebiča alebo informácie o
      vhodnom príslušenstve, stave vašich zariadení a technickej podpore v
      prípade poruchy. Zjednodušte si život s chytrými pomocníkmi, ktorí sa
      postarajú o dokonalé výsledky.
    </p>
  </div>
  <div class="row">
    <div class="col-md-4">
      <img
        class="img-responsive"
        src="images/Sources/Home_Connect_Logo_RGB_2.jpg"
      />
    </div>
    <div class="col-md-8">
      <P><b>Nahliadnite do chladničky, aj keď nie ste doma</b></P>
      <P class="text-justify"
        >Vďaka integrovanej kamere v chladničke môžete priamo v obchode na
        tablete alebo telefóne skontrolovať, aké potraviny vám doma vlastne
        chýbajú. A aby zostali potraviny dlhšie čerstvé, ponúka navyše Home
        Connect dôležité tipy pre ich uchovávanie a odporúčania, ktorú
        priehradku vybrať a akú teplotu nastaviť. Môžete tak vždy jednoducho
        vytvoriť dokonalé podmienky pre uchovanie svojich potravín.</P
      >
      <P
        ><b
          >Vaša práčka teraz vie všetko najlepšie – pre váš jednoduchší život</b
        ></P
      >
      <P class="text-justify"
        >Odteraz už sa nikdy nebudete musieť pýtať mamičky, ako najlepšie vyprať
        bielizeň alebo sa vy-hovárať, že neviete práčku správne nastaviť. Práčka
        to zvládne sama – pomocou funkcie Easy Start v aplikácii Home Connect.
        Stačí postupovať podľa návodu, nastaviť druh bielizne a úroveň
        znečistenia a spotrebič sám podľa zadaných informácií vyberie ten
        najvhodnejší program a zjed-noduší vám tak domáce práce. S Home Connect
        navyše získate užitočné tipy alebo informácie o spotrebe vody aj
        energie. Práčka upozorní nielen na dokončenie cyklu a nutnosť vybratia
        bie-lizne, ale postráži tiež, či nedochádza prací prostriedok. Sušička
        zasa kontroluje a upozorňuje, kedy je potrebné vyčistiť filter alebo
        vyprázdniť nádržku na vodu.</P
      >
      <P><b>Varná doska a odsávač pár ako dokonalý pár</b></P>
      <P class="text-justify"
        >Teraz môže vaša varná doska a odsávač pár fungovať v perfektnej
        synchronizácii a zaistiť opti-málne odsávanie. Jednoducho ovládajte a
        kontrolujte nastavenie na diaľku na vašom chytrom telefóne alebo
        tablete. A ako bonus si vďaka aplikácii Home Connect vytvorte dokonalú
        atmosfé-ru v kuchyni podľa aktuálnej nálady – s novým LED osvetlením na
        odsávači pár Bosch.</P
      >
      <P
        ><b
          >Vynikajúcu hostinu ľahko a rýchlo zvládnu aj úplný začiatočníci</b
        ></P
      >
      <P class="text-justify"
        >S rúrou s aplikáciou Home Connect získate celú kuchárku receptov na
        mieru daného modelu, tipy na varenie, názorné videá aj dôležité
        informácie o najbežnejších ingredienciách. Vďaka po-drobnému návodu
        zvládne prípravu vynikajúceho menu hravo aj úplný začiatočník.
        Nastavenia pre prípravu vybraného pokrmu pošle aplikácia priamo do rúry.
        Stačí len vložiť jedlo dovnútra a potom sa nechať viesť. Na diaľku
        môžete jednoducho skontrolovať, v akej fáze sa proces nachá-dza a kedy
        presne je jedlo pripravené. Mimo domov sa môžete tiež uistiť, či ste
        rúru vypli.</P
      >
      <P
        ><b
          >Kde nájdete recept na rôzne kávové špeciality? V aplikácii vášho
          kávovaru</b
        ></P
      >
      <P class="text-justify"
        >Veľmi jednoducho sa z vás môže stať skúsený barista – vďaka novému plno
        automatickému ká-vovaru s technológiou Home Connect. Vyberte si z
        mimoriadne širokej ponuky najrôznejších druhov kávy a uložte si svoje
        obľúbené nastavenia pomocou funkcie CoffeeWorld v rámci apliká-cie.
        Jedinú vec, ktorú za vás neurobíme, je vychutnanie si lahodnej šálky
        práve pripravenej kávy.</P
      >
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-8">
      <p>
        <b
          >Umývačka riadu sama upozorní, že dochádzajú tablety alebo leštidlo</b
        >
      </p>
      <p class="text-justify">
        Domáce práce zvládnete kedykoľvek a kdekoľvek. Vyberte Easy Start v
        aplikácii Home Connect a umývačka riadu vám odporučí najvhodnejší
        program pre váš riad. Získate tak navždy najlepšie-ho odborníka na
        umývanie riadu vo svojej domácnosti. Umývačka riadu navyše zasiela na
        chytrý telefón alebo tablet upozornenie, že doumývala, dochádza leštidlo
        alebo tablety.
      </p>
      <p><b>Bezpečnosť na prvom mieste</b></p>
      <p class="text-justify">
        O svoju bezpečnosť sa báť nemusíte. Prístup k informáciám je možný len
        po prihlásení sa do užívateľského účtu pomocou Wi-Fi. Systém navyše
        nedovolí, aby ste sa prihlásili z nezabezpe-čenej siete bez hesla.
        Samozrejmosťou sú tiež bezpečnostné certifikáty, firewall na serveri a
        ochrana heslom.
      </p>
      <p><b>Chcete si to sami vyskúšať?</b></p>
      <p class="text-justify">
        Príďte sa presvedčiť do unikátneho Brand Experience Center spoločnosti
        BSH – prvého zážitko-vého centra venovaného výhradne domácim
        spotrebičom. Nielen spotrebiče s Home Connect si môžete prezrieť a
        vyskúšať na adrese Radlická 350, Praha 5. Uvidíte, že už potom nebudete
        chcieť nič iného.
      </p>
    </div>
    <div class="col-md-4">
      <img
        src="images\Bosch\hc\Bosch-homeconnect-oven.jpg"
        class="img-responsive"
      />
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-4"><img src="images\Bosch\hc\Bosch-homeconnect-coffee-machine.jpg" class="img-responsive"></div>
    <div class="col-md-4"><img src="images\Bosch\hc\Bosch-homeconnect-dishwasher.jpg" class="img-responsive"></div>
    <div class="col-md-4"><img src="images\Bosch\hc\Bosch-homeconnect-fridge.jpg" class="img-responsive"></div>
  </div>
  <br />
  <br />
  <div class="row">
      <div class="col-md-6"><img src="images\Bosch\hc\Bosch-homeconnect-hob-hood.jpg" class="img-responsive"></div>
      <div class="col-md-6"><img src="images\Bosch\hc\Bosch-homeconnect-washer-dryer.jpg" class="img-responsive"></div>
    </div>
</section>
