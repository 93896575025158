<section id="portfolio" class="section portfoliosection">
  <div class="container-fluid">
     <div class="row">
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/moderne']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Moderné</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Modern kitchens/Top_1211_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/country']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Country-house</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Country-house kitchens/SHERWOOD_2041_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/classic']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Klasické</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Classic Kitchens/Frame_3358_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/comfort']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Komfortné</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Comfort kitchens/Top_1466_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/robus']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Robusné kuchyne</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Extremely Robust kitchens/Highline_7246_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/line45']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Line 45</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Kitchenettes/Flair-S_40095_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/blackline']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Blackline</h3>
                 </div>
              </div>
              <img src="images/Kitchen/Blackline/Resopal_3416.jpg" class="img-responsive" alt=""> </a> 
        </div>
        <div class="col-sm-6 portfolio-item"> <a [routerLink]="['/ykitchen']" class="portfolio-link">
              <div class="caption">
                 <div class="caption-content">
                    <h3>Y kuchyne</h3>
                 </div>
              </div>
              <img src="images/Kitchen/The Y kitchen/EDITION_2706_1.jpg" class="img-responsive" alt=""> </a> 
        </div>
       </div>
  </div>
</section>