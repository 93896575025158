import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-SX836X00PE",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class SX836X00PEComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "SX836X00PE";
    this._imagePath =
      "images/siemens/sx836x00pe/mcsa01765161-g6541-sx836x00pe-1200073-corr-def.jpg";
    this._headerText =
      "varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere.";
    this._productPrice = 949;
    this._productDesc = "../../../ProductDesc/SX836X00PE.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/sx836x00pe/01.jpg");    
      this.setImages(1, '', "images/siemens/sx836x00pe/02.jpg");    
      this.setImages(2, '', "images/siemens/sx836x00pe/03.jpg");
      this.setImages(3, '', "images/siemens/sx836x00pe/04.jpg");
      this.setImages(4, '', "images/siemens/sx836x00pe/05.jpg");
      this.setImages(5, '', "images/siemens/sx836x00pe/06.jpg");
      this.setImages(6, '', "images/siemens/sx836x00pe/07.jpg");
  }
}
