import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-CS858GRB6",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class CS858GRB6Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "CS858GRB6";
    this._imagePath =
      "images/siemens/cs858grb6/mcsa01936121-1342364-cs858grb6-def.jpg";
    this._headerText =
      "Skráťte si čas varenia pomocou funkcie predohrevu. Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect.";
    this._productPrice = 2259;
    this._productDesc = "../../../ProductDesc/CS858GRB6.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });
     
      this.setImages(0, '', "images/siemens/cs858grb6/01.jpg");    
      this.setImages(1, '', "images/siemens/cs858grb6/02.jpg");    
      this.setImages(2, '', "images/siemens/cs858grb6/03.jpg");
  }
}
