import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LD97AA670",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LD97AA670Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LD97AA670";
    this._imagePath =
      "images/siemens/LD97AA670/01.jpg";
    this._headerText =
      "Elegantný a decentný downdraft - spodný odťah za varným panelom je možné ľahko riešiť na odťah von v prípade potreby.";
    this._productPrice = 2079;
    this._productDesc = "../../../ProductDesc/LD97AA670.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/LD97AA670/01.jpg");    
      this.setImages(1, '', "images/siemens/LD97AA670/02.jpg");    
      this.setImages(2, '', "images/siemens/LD97AA670/03.jpg");
      this.setImages(3, '', "images/siemens/LD97AA670/04.jpg");
      this.setImages(4, '', "images/siemens/LD97AA670/05.jpg");
      this.setImages(5, '', "images/siemens/LD97AA670/06.jpg");
      this.setImages(6, '', "images/siemens/LD97AA670/07.jpg");
      this.setImages(7, '', "images/siemens/LD97AA670/08.jpg");
      this.setImages(8, '', "images/siemens/LD97AA670/09.jpg");
      this.setImages(9, '', "images/siemens/LD97AA670/10.jpg");
  }
}