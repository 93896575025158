import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-HS836GVB6",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class HS836GVB6Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "HS836GVB6";
    this._imagePath =
      "images/siemens/hs836gvb6/d01d40913e0cd98bfa217ae77c8f2370.jpg";
    this._headerText =
      "Skráťte si čas varenia pomocou funkcie predohrevu. Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect.";
    this._productPrice = 2039;
    this._productDesc = "../../../ProductDesc/HS836GVB6.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/hs836gvb6/01.jpg");
      this.setImages(1, '', "images/siemens/hs836gvb6/02.jpg");
      this.setImages(2, '', "images/siemens/hs836gvb6/03.jpg");
      this.setImages(3, '', "images/siemens/hs836gvb6/04.jpg");
  }
}
