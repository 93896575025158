import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-KI87SSD30",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class KI87SSD30Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "KI87SSD30";
    this._imagePath =
      "images/siemens/KI87SSD30/01.jpg";
    this._headerText =
      "iQ500 coolEfficiency zabudovateľná kombinácia chladnička/mraznička ploché pánty, systém softClose (jemné dovieranie dverí)";
    this._productPrice = 1135;
    this._productDesc = "../../../ProductDesc/KI87SSD30.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/KI87SSD30/01.jpg");    
      this.setImages(1, '', "images/siemens/KI87SSD30/02.jpg");    
      this.setImages(2, '', "images/siemens/KI87SSD30/03.jpg");    
      this.setImages(3, '', "images/siemens/KI87SSD30/04.jpg");       
      this.setImages(4, '', "images/siemens/KI87SSD30/05.jpg");       
      this.setImages(5, '', "images/siemens/KI87SSD30/06.jpg");       
      this.setImages(6, '', "images/siemens/KI87SSD30/07.jpg");       
  }
}
