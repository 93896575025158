import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LC97FVW60S",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LC97FVW60SComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LC97FVW60S";
    this._imagePath =
      "images/siemens/lc97fvw60s/mcsa01747313-stp-lc97fvw60s-1185149-def.jpg";
    this._headerText =
      "Odsávač pár s plochým designom s vysokým výkonom.";
    this._productPrice = 1469;
    this._productDesc = "../../../ProductDesc/LC97FVW60S.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/lc97fvw60s/01.jpg");    
      this.setImages(1, '', "images/siemens/lc97fvw60s/02.jpg");    
      this.setImages(2, '', "images/siemens/lc97fvw60s/03.jpg");
      this.setImages(3, '', "images/siemens/lc97fvw60s/04.jpg");
      this.setImages(4, '', "images/siemens/lc97fvw60s/05.jpg");
      this.setImages(5, '', "images/siemens/lc97fvw60s/06.jpg");
      this.setImages(6, '', "images/siemens/lc97fvw60s/07.jpg");
      this.setImages(7, '', "images/siemens/lc97fvw60s/08.jpg");
      this.setImages(8, '', "images/siemens/lc97fvw60s/09.jpg");
      this.setImages(9, '', "images/siemens/lc97fvw60s/10.jpg");
      this.setImages(10, '', "images/siemens/lc97fvw60s/11.jpg");
      this.setImages(11, '', "images/siemens/lc97fvw60s/12.jpg");
      this.setImages(12, '', "images/siemens/lc97fvw60s/13.jpg");
  }
}
