import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LF98BA572S",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LF98BA572SComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LF98BA572S";
    this._imagePath =
      "images/siemens/LF98BA572S/01.jpg";
    this._headerText =
      "Ostrovčekový odsávač pár sa perfektne hodí nad všetky varné ostrovy, montáž na strop.";
    this._productPrice = 1129;
    this._productDesc = "../../../ProductDesc/LF98BA572S.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/LF98BA572S/01.jpg");    
      this.setImages(1, '', "images/siemens/LF98BA572S/02.jpg");    
      this.setImages(2, '', "images/siemens/LF98BA572S/03.jpg");
      this.setImages(3, '', "images/siemens/LF98BA572S/04.jpg");
      this.setImages(4, '', "images/siemens/LF98BA572S/05.jpg");
      this.setImages(5, '', "images/siemens/LF98BA572S/06.jpg");
      this.setImages(6, '', "images/siemens/LF98BA572S/07.jpg");
  }
}