import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LC87KIM60S",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LC87KIM60SComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LC87KIM60S";
    this._imagePath =
      "images/siemens/lc87kim60s/mcsa01746201-stp-lc87kim60s-1183972-def.jpg";
    this._headerText =
      "Odsávač pár s nakloneným designom a vysokým výkonom odvetrávania.";
    this._productPrice = 945;
    this._productDesc = "../../../ProductDesc/LC87KIM60S.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/lc87kim60s/01.jpg");    
      this.setImages(1, '', "images/siemens/lc87kim60s/02.jpg");    
      this.setImages(2, '', "images/siemens/lc87kim60s/03.jpg");
      this.setImages(3, '', "images/siemens/lc87kim60s/04.jpg");
      this.setImages(4, '', "images/siemens/lc87kim60s/05.jpg");
      this.setImages(5, '', "images/siemens/lc87kim60s/06.jpg");
      this.setImages(6, '', "images/siemens/lc87kim60s/07.jpg");
      this.setImages(7, '', "images/siemens/lc87kim60s/08.jpg");
  }
}
