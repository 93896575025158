import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-CP465AGB0",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class CP465AGB0Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "CP465AGB0";
    this._imagePath =
      "images/siemens/CP465AGB0/01.jpg";
    this._headerText =
      "45 cm vysoká mikrovlnná rúra s horúcim vzduchom a funkciou para šetrne pripravia vaše pokrmy v tom najkratšom možnom čase.";
    this._productPrice = 1169;
    this._productDesc = "../../../ProductDesc/CP465AGB0.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/CP465AGB0/01.jpg");    
      this.setImages(1, '', "images/siemens/CP465AGB0/02.jpg");    
      this.setImages(2, '', "images/siemens/CP465AGB0/03.jpg");
      this.setImages(3, '', "images/siemens/CP465AGB0/04.jpg");
      this.setImages(4, '', "images/siemens/CP465AGB0/05.jpg");
      this.setImages(5, '', "images/siemens/CP465AGB0/06.jpg");
      this.setImages(6, '', "images/siemens/CP465AGB0/07.jpg");
      this.setImages(7, '', "images/siemens/CP465AGB0/08.jpg");
      this.setImages(8, '', "images/siemens/CP465AGB0/09.jpg");
      this.setImages(9, '', "images/siemens/CP465AGB0/10.jpg");
  }
}