<app-header></app-header>
<section id="portfolio">
  <div class="row">
    <div class="col-md-12">
      <h4 class="text-center">Inteligentné spotrebiče značky Siemens</h4>
      <br />
      <br />
    </div>
    <div class="row">
      <div class="col-md-4">
        <img
          class="img-responsive"
          src="images/Sources/Home_Connect_Logo_RGB_2.jpg"
        />
      </div>
      <div class="col-md-8">
        <P><b>Váš domov v jednej aplikácii</b></P>
        <P class="text-justify"
          >„Žijeme prepojení“ je hlavnou myšlienkou spoločnosti Siemens, ktorá
          je jedným z popredných výrobcov v oblasti spotrebnej elektroniky a
          domácich spotrebičov. Aplikácia Home Connect umožňuje vybraným
          spotrebičom Siemens s Wi-Fi a ich užívateľom pripojiť sa do domácej
          siete, prípadne internetu, aby tak mali prehľad o ich činnosti v
          intuitívnej aplikácii v telefóne či tablete. Prostredníctvom nej ich
          možno nastavovať, diagnostikovať či monitorovať. Obsluha aplikácie,
          ktorá je k dispozícii aj v slovenskom jazyku, je veľmi jednoduchá a
          vôbec nie je potrebné byť technicky zdatný na to, aby bolo možné
          spotrebiče týmto spôsobom obslúžiť. Stačí nastaviť základné vstupné
          údaje a systém Home Connect sám zvolí najvhodnejší program či
          parametre nastavenia. <br /><br />
          Aplikácia však ponúka oveľa viac ako len zaputie či vypnutie
          spotrebičov na diaľku. Vďaka nej je ich používanie mnohokrát
          efektívnejšie, ale aj jednoduchšie a zábavnejšie. Home Connect tak
          zvyšuje užívateľský komfort a umožňuje využitie všetkých funkcií
          spotrebiča, dokonca aj takých, ktoré človek bežne nepoužíva,
          respektíve im nerozumie.
        </P>
        <P><b>Kontrola aj ovládanie na diaľku</b></P>
        <P class="text-justify">
          Vďaka systému Home Connect je možné prostredníctvom diaľkového
          monitoringu skontrolovať a zmeniť nastavenie každého spotrebiča, ktorý
          je do systému zapojený. Do chladničky sa dokonca možno pozrieť
          kedykoľvek a kdekoľvek, keď je potrebné, a pripraviť si presné plány
          varenia, napríklad aj za použitia mobilnej aplikácie s receptmi.
          Chladnička je totiž vybavená dvoma vysokovýkonnými kamerami, ktoré
          spravia fotografiu zakaždým, keď sa spotrebič zavrie. Takto si majiteľ
          môže cestou z práce skontrolovať, či mu niečo na prípravu jedla
          nechýba, a okamžite vybaviť nákup. Prepojenie práčky, sušičky či
          umývačky zas poslúži aj na vyhľadanie toho najefektívnejšieho programu
          na jednotlivé typy bielizne alebo riadu.
        </P>
        <P><b>Zábavná príprava jedla aj pre „nekuchárov“</b></P>
        <P class="text-justify">
          V prípade vstavaných rúr je zas možné získať prístup k veľkému
          množstvu receptov priamo v aplikácii Home Connect. Tá krok za krokom
          prevedie človeka celou prípravou jedla a následne odošle parametre
          pečenia priamo do rúry. Jedlo stačí len vložiť dnu a o nič iné sa
          nestarať, rúra už bude vedieť, čo má robiť. Do aplikácie Home Connect
          však môžete zapojiť aj vybrané odsávače pár a varné dosky. Diaľkovým
          monitoringom a ovládaním je kontrola ich nastavení či ich úprava
          úplnou hračkou. Odsávače sú navyše vybavené funkciou emotionLight Pro,
          v ktorej si možno vybrať zo širokej farebnej škály ich osvetlenia.
          Rovnako varná rúra, aj varná doska má prístup ku knižnici
          inšpiratívnych receptov, ktorých nastavenie sa dá poslať priamo do
          spotrebiča.
        </P>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-8">
        <span class="center">
        <p><b>Inteligentné kávovary</b></p>
        <p class="text-justify">
          Týmto jedinečným systémom sú vybavené dokonca aj kávovary značky
          Siemens, a tak výroba rôznych kávových špecialít pre množstvo hostí je
          so zoznamom káv hračkou. Objednávka sa zaznačí v aplikácii a pošle do
          kávovaru, ktorý sa o všetko postará. Pri kávovaroch, ktoré majú malý
          displej, je ovládanie ich nastavení cez aplikáciu oveľa pohodlnejšie
          ako na samotnom spotrebiči.
        </p>

        <p><b>Praktické informácie vždy poruke</b></p>
        <p class="text-justify">
          Home Connect poskytuje okrem iného aj praktický informačný systém o
          stave spotrebičov v domácnosti. Ak napríklad práčka disponuje systémom
          i-Dos, automatickým dávkovaním tekutého prostriedku, tak vďaka systému
          príde do telefónu či tabletu upozornenie, keď prací prostriedok v
          zásobníku dochádza. Človek si tak môže prací prostriedok rovno pridať
          do nákupného zoznamu. Sušička zase zasiela informácie o potrebe
          vyčistenia filtra alebo vyprázdnení nádržky na vodu. A na to všetko
          nie je potrebné pri spotrebiči fyzicky byť.
        </p>

        <p><b>Bezpečnosť a ochrana súkromia najvyššou prioriotou</b></p>
        <p class="text-justify">
          Prioritou značky Siemens je, samozrejme, najvyššia miera ochrany
          spotrebiteľov. Aplikácia Home Connect disponuje certifikátom TÜV TRUST
          IT „Trusted App“, potvrdzujúcim jej bezpečnosť. Samotný proces
          zapojenia sa do systému Home Connect je veľmi jednoduchý a netrvá viac
          ako 10 minút. Prístup k nemu a jednotlivým spotrebičom je však
          umožnený len po prihlásení sa do užívateľského účtu. Nikto bez
          prihlasovacích údajov cez internet nemá k spotrebiču prístup. Navyše
          nie je môžné pripojiť ho do akejkoľvek Wi-Fi sieti, ale len do
          zabezpečenej. K otvorenej sieti bez hesla sa systém vôbec nepripojí.
          Ďalšie zabezpečenie aplikácie Home Connect predstavujú bezpečnostné
          certifikáty, firewall na serveri a ochrana heslom. Spotrebiče však nie
          je nutné do systému Home Connect zapojiť, možno ich, samozrejme,
          používať aj tradičným spôsobom.
        </p>
        <p class="text-justify">
          Náklady na potrebnú domácu infraštruktúru sú minimálne. Rozhodnutie
          používať inteligentný domáci spotrebič ovládaný na diaľku prináša
          výhody rozšírených funkcií, dostupnosti, flexibility,
          transparentnosti, ako aj atraktívne obstarávacie ceny. Aplikáciu Home
          Connect si môžno stiahnuť a používať zadarmo, bez toho, aby s ňou
          súviseli akékoľvek skryté vedľajšie náklady
        </p>
      </span>
      </div>
      <div class="col-md-4"> <br /><img class="img-responsive" src="images/Sources/S2.jpg"><br /></div>
    </div>
    <div class="row" >
        <div class="col-md-4"><img class="img-responsive" src="images/Sources/S1.jpg"></div>
        <div class="col-md-4"><img class="img-responsive" src="images/Sources/S3.jpg"></div>
        <div class="col-md-4"><img class="img-responsive" src="images/Sources/S4.jpg"></div>
    </div>
  </div>
</section>
