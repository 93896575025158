<app-kuchynebuttons></app-kuchynebuttons>
   <!--Kitchen list-->
   <section class="section kitchen-section">
    <div class="row">
       <div class="kitchen-list">
      <ng-container *ngFor="let img of images">
          <div class="col-md-3">
              <div class="zoom-kitchen">
                 <div class="kitchen-list-item">
                    <img [src]="img.modal.img" (click)="openImageModalRowDescription(img)" class="img-responsive" />
                  </div>
              </div>
              <p class="kitchen-type">{{img.modal.description}}</p>
          </div>
      </ng-container>
       </div>
    </div>
 </section>
 <!--Kitchen list-->
 <!-- <ks-plain-gallery [id]="8" [images]="images"></ks-plain-gallery> -->