import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-comfort',
  templateUrl: '../gallerybase.html'
})
export class ComfortComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'Miami_3146', "images/Kitchen/Comfort kitchens/Miami_3146_1.jpg");
    this.setImages(1, 'Resopal_3431', "images/Kitchen/Comfort kitchens/Resopal_3431_1.jpg");  
    this.setImages(2, 'Sky_3461', "images/Kitchen/Comfort kitchens/Sky_3461_1.jpg");
    this.setImages(3, 'Top_1466', "images/Kitchen/Comfort kitchens/Top_1466_1.jpg");  
    this.setImages(4, 'Top_1416', "images/Kitchen/Comfort kitchens/Top_1416_1.png");
    this.setImages(5, 'Miami_3146', "images/Kitchen/Comfort kitchens/Miami_3146_1.jpg");
    this.setImages(6, 'Miami_3146', "images/Kitchen/Comfort kitchens/Maiami_3146_D3.jpg");
    this.setImages(7, 'SKY_3450', "images/Kitchen/Comfort kitchens/SKY_3450.jpg");
    this.setImages(8, 'SOFIE-B_XL4799_OSLO15_6030', "images/Kitchen/Comfort kitchens/SOFIE-B_XL4799_OSLO15_6030.jpg");
    this.setImages(9, 'SOFIE-B_XL4799_OSLO15_6030', "images/Kitchen/Comfort kitchens/SOFIE-B_XL4799_OSLO15_6030_.jpg");
    this.setImages(10, 'TOP_1331_D', "images/Kitchen/Comfort kitchens/TOP_1331_D.jpg");
    this.setImages(11, 'TOP_1331', "images/Kitchen/Comfort kitchens/TOP_1331.jpg");
    this.setImages(12, 'TOP_1406_D', "images/Kitchen/Comfort kitchens/TOP_1406_D.jpg");
    this.setImages(13, 'TOP_1436', "images/Kitchen/Comfort kitchens/TOP_1436_.jpg");
    this.setImages(14, 'TOP_1461', "images/Kitchen/Comfort kitchens/TOP_1461.jpg");
    this.setImages(15, 'TOP_1481_D', "images/Kitchen/Comfort kitchens/TOP_1481_D.jpg");
    this.setImages(16, 'TOP_1481', "images/Kitchen/Comfort kitchens/TOP_1481.jpg");
    this.setImages(17, 'OPAL-H_5650', "images/Kitchen/Comfort kitchens/OPAL-H_5650.jpg");
    this.setImages(18, 'OPAL-H_5690', "images/Kitchen/Comfort kitchens/OPAL-H_5690.jpg");
    this.setImages(19, 'ARTE-B_3389', "images/Kitchen/Comfort kitchens/ARTE-B_3389.jpg");
    
    
  }

}
