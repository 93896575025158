import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hc-siemens',
  templateUrl: './hc-siemens.component.html',
  styleUrls: ['./hc-siemens.component.css']
})
export class HcSiemensComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
