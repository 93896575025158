<section id="portfolio">
  <div class="row">
    <div class="col-md-12">
      <br />
      <br />
      <br />
      <br />
      <br />
      <img
        src="images\Bora\Headerbild_Bora_Prinzip_L_Gif_Animation_neu.gif"
        class="img-responsive"
      />
    </div>
  </div>
  <br />
  <br />
  <div class="col-md-12">
    <h3 class="text-center">Ďalšie informácie o produktoch BORA a ceny nájdete na stránke <a href="https://www.madeingermany.sk/c/rury-a-varne-dosky/varne-dosky1/bora" target="_blank">www.madeingermany.sk</a> </h3>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div class="row">
    <div class="col-md-12">
      <p class="text-justify">
        V našom kuchynskom štúdiu máme pre vás vystavené a funkčné všetky tri
        produkty BORA – Basic, Classic a Professional 2.0 BORA - ideálny,
        momentálne jeden z mála spoľahlivých a najúčinnejších odsávačov v
        kombinácii so sklokeramickou varnou doskou, s indukčnou varnou doskou, s
        varnou doskou s flexi zónou, s plynovou varnou doskou, s doskou Tepan
        Yaki a s indukčným Wokom. Výpary a mastnoty sa nedostávajú do ovzdušia a
        ani na šaty a vlasy osôb. Pri odsávačoch pár BORA nedochádza k veľkému
        úniku tepla z priestoru ako pri bežných typoch odsávačov. Samozrejme je
        veľká škála technicky výkonných a spoľahlivých odsávačov, ale odsávače
        BORA (zakladateľom je Willi Bruckbauer) sa javia ako jedny z
        najúčinnejších. Nezanedbateľná je aj úspora priestoru - motor môže byť
        uložený pod skrinkami za soklom, na fasáde resp. sú možné viaceré
        riešenia, s ktorými vám radi pomôžeme.
      </p>
      <p>
        Kuchynské výpary a pachy nenechá BORA vôbec stúpať a odsáva ich rovno
        tam, kde vznikajú priamo na varnej doske, priamo z hrnca, pekáča,
        panvice, grilu. Nie sú to žiadne kúzla, ale aplikovaná fyzika, podľa
        základných princípov náuky o prúdení. Využíva sa k tomu celkom
        jednoduché priečne prúdenie, ktoré je väčšie ako rýchlosť stúpania
        kuchynských výparov. Výpary stúpajú rýchlosťou 1 m/s a BORA má odťah 4
        m/s.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <h3>Klíma</h3>
      <h5>
        Konečne môžete variť na čerstvom vzduchu - konečne môžete variť bez
        rušivého telesa kuchynského odsávača pár.
      </h5>
      <p class="text-justify">
        Čím ďalej sa kuchynské výpary rozšíria, než sa ich podarí odsať, tým
        viac sa v priestore šíria pachové a tukové častice, ukladajú sa na
        povrch nábytku a prenikajú látkami. Výsledok: Vy, Vaša rodina a Vaši
        hostia dostanú nemilý prídel pachov z pečenia, varenia a dusenia ešte na
        niekoľko dní. BORA s tým efektívne skoncuje. Tukové častice uvoľnené
        počas varenia sa už v priestore nerozptyľujú, zachytáva ich totiž tukový
        filter z nerezovej ocele.
      </p>
      <h3>Voľný výhľad</h3>
      <h5>Žiadne rušivé teleso kuchynského odsávača pár vo výške hlavy.</h5>
      <p class="text-justify">
        BORA Vás oslobodí od limitov bežných kuchynských digestorov. Žiadne
        hrany a rohy vo výške hlavy, o ktoré by ste mohli zavadiť. Žiadny ohnutý
        chrbát. V zornom poli Vám neprekáža para a nezahmlia sa Vám okuliare.
        Výhľad bez obmedzenia. Práve v prípade otvorených kuchýň už nestoja
        kuchárky a kuchári vďaka systému BORA stranou, ale sú súčasťou
        inšpirujúcej pohostinnosti.
      </p>
    </div>
    <div class="col-md-4">
      <img
        src="images\Bora\csm_BORA_Classic_Header_2018a_L_f0b8c8669f.jpg"
        class="img-responsive bora-image"
      />
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-4">
      <img
        src="images\Bora\csm_BORA_Professional_Header_2018a_L_4e30d646c8.jpg"
        class="img-responsive bora-image"
      />
    </div>
    <div class="col-md-8">
      <h3>Sloboda plánovania</h3>
      <h5>Nové možnosti v kuchynskej architektúre.</h5>
      <p class="text-justify">
        Okrem mnohých technických výhod, ktoré si môžete vďaka BORA užívať, to
        sú predovšetkým úplne nové možnosti pri plánovaní kuchyne, a o nich Vás
        dokáže BORA presvedčiť. Môžete robiť prakticky čokoľvek, čo ste si už
        dlho želali. Variť pri okne a pod šikmým stropom, mať kuchynský
        ostrovček bez rušivého telesa odsávača pár, viac miesta pre závesné
        skrinky v malej kuchyni, minimalizmus bez medzier ... BORA je tak
        meradlom pre novú kuchynskú estetiku - úplne bez rušivého telesa
        kuchynského odsávača pár.
      </p>
      <h3>Ticho</h3>
      <h5>Výrazne tichšie než konvenčné kuchynské odsávače pár</h5>
      <p class="text-justify">
        Hluk znamená stres. V mnohých životných situáciách mu bohužiaľ nemôžeme
        uniknúť. Prečo sa ale práve pri varení vystavujeme dobrovoľne rámusu od
        kuchynského odsávača pár? Predsa len nás tu skoro 70 dB (A) zaťažuje
        priamo vo výške hlavy. Jednoznačne príliš hlučné a stresujúce. BORA je
        naopak pri varení a pečení sotva počuť - aj pri nastavení na najvyšší
        prevádzkový stupeň vznikajú len minimálne hluky, ktoré sú dokonca
        tichšie ako pečenia steaku. Umožňuje to premyslená technika s tichým
        chodom motorov, ktoré nie sú umiestnené vo výške hlavy. O to ostatné sa
        postará perfektné oddelenie pár a radosť z varenia - špeciálne v
        otvorených kuchyniach - Vám už nič nepokazí.
      </p>
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-8">
      <h3>Úspora energie</h3>
      <h5>BORA neplytvá cennými zdrojmi.</h5>

      <p class="text-justify">
        Účet je jednoduchý: konvenčné kuchynské odsávače pár odsávajú z Vašej
        kuchyne až 1 000 m3 vzduchu za hodinu. To nestojí len viac elektriny, to
        je poznať aj na faktúre za kúrenie. Digestor neodsáva konieckoncov len
        výpary, ale aj cenné izbové teplo - rýdze plytvanie cennými zdrojmi.
        BORA pracuje v súlade so životným prostredím a neodsáva ani polovicu
        vzduchu ako bežné kuchynské odsávače pár. Rozumne použitá rýchlosť
        prúdenia miesto veľkého objemu vzduchu je našou devízou. Čo dokážu
        oceniť najmä všetky zodpovedné kuchárky a všetci zodpovední kuchári v
        nízkoenergetických, príp. pasívnych domoch.
      </p>

      <h3>Čistenie</h3>
      <h5>Jednoduché čistenie v umývačke.</h5>

      <p class="text-justify">
        Čistenie kuchynských digestorov je problematika sama o sebe: Je to
        prácne, časovo náročné, často realizovateľné len za použitia náradia, a
        k tomu všetkému iba s pochybným výsledkom. Poznatok, ktorý platí ako pre
        vnútorné súčasti, tak aj pre veľké povrchy. Komplikované najmä v prípade
        stropného vetrania: pre demontáž a montáž filtra a vyčistenie musíte
        vyliezť na rebrík rovno trikrát. U výrobkov BORA je čistenie jednoduché
        ako detská hra. Všetky pohyblivé časti je možné demontovať iba rukou,
        úplne bez použitia náradia. Naskladajte ich jednoducho do svojej
        umývačky - a hotovo.
      </p>
    </div>
    <div class="col-md-4">
      <p class="text-center">
      <img
        src="images\Bora\csm_Headerbild_BORA_Basic_L_7985e784bc.jpg"
        class="img-responsive bora-image"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Kvalita</h3>
      <h5>Kvalitné materiály pre dlhú životnosť.</h5>
      <p class="text-justify">
        Keď ide o kvalitu našich výrobkov, je pre nás dobré len to najlepšie.
        Kvalitné materiály zaručujúce vysokú funkčnosť a životnosť sa zlúčili do
        prémiového systému bez kompromisov. Všetky kovové pohľadové diely
        digestorov značky BORA vyrábame z rýdzej nerezovej ocele. Všetky
        sklokeramickej varnej dosky v našich troch produktových líniách sú zo
        sklokeramiky značky Schott, bez obsahu ťažkých kovov - čo je mimochodom
        celosvetový unikát. Aby ste mali z varenia s výrobkami BORA dlhotrvajúcu
        radosť.
      </p>
    </div>
  </div>
</section>
