   <!--Buttons Section-->
   <section class="section">
    <div class="row">
       <div class="col-md-12">
          <div class="button-list">
             <a class="button" [style.font-weight]="this.GetColor('/moderne')" [routerLink]="['/moderne']">Moderné</a>
             <a class="button" [style.font-weight]="this.GetColor('/country')" [routerLink]="['/country']">Country-house</a>
             <a class="button" [style.font-weight]="this.GetColor('/classic')" [routerLink]="['/classic']">Klasické</a>
             <a class="button" [style.font-weight]="this.GetColor('/comfort')" [routerLink]="['/comfort']">Komfortné</a>
             <a class="button" [style.font-weight]="this.GetColor('/robus')" [routerLink]="['/robus']">Robusné kuchyne</a>
             <a class="button" [style.font-weight]="this.GetColor('/line45')" [routerLink]="['/line45']">Line 45</a>
             <a class="button" [style.font-weight]="this.GetColor('/blackline')" [routerLink]="['/blackline']">BlackLine</a>
             <a class="button" [style.font-weight]="this.GetColor('/ykitchen')" [routerLink]="['/ykitchen']">Y kuchyne</a>
          </div>
       </div>
    </div>
 </section>
 <!--Buttons Section-->