import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';


@Component({
  selector: 'app-line45',
  templateUrl: '../gallerybase.html',
})
export class Line45Component extends GalleryBase implements OnInit {
   
   ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'Flair-S_40095', "images/Kitchen/Kitchenettes/Flair-S_40095_1.jpg");
    this.setImages(1, 'Držák utěrek', "images/Kitchen/Kitchenettes/Držák utěrek.jpg");    
    this.setImages(2, 'FLAIR-S_40095', "images/Kitchen/Kitchenettes/FLAIR-S_40095_FLAIR-S_40050__.jpg");
    this.setImages(3, 'Flair-S_40095', "images/Kitchen/Kitchenettes/FLAIR-S_40095_FLAIR-S_40050.jpg");
    this.setImages(4, 'Flair-S_40095', "images/Kitchen/Kitchenettes/FLAIR-S_40095.jpg");
    this.setImages(5, 'Karusel', "images/Kitchen/Kitchenettes/Karusel.jpg");
    this.setImages(6, 'Soklový systém', "images/Kitchen/Kitchenettes/Soklový systém.jpg");
    this.setImages(7, 'TOP_1496', "images/Kitchen/Kitchenettes/TOP_1496.jpg");
    this.setImages(8, 'Závěsný systém', "images/Kitchen/Kitchenettes/Závěsný systém.jpg");    
  }
}
