import { Component, OnInit } from '@angular/core';
import { ProductpanelComponent } from '../../shared/productpanel/productpanel.component';

@Component({
  selector: 'app-studioline',
  templateUrl: './studioline.component.html',
  styleUrls: ['./studioline.component.css']
})
export class StudiolineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
