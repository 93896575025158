<app-header></app-header>
<section>
  <div class="ssl-logo">
    <img src="images/Sources/siemens-studioline.png" height="50px;" />
  </div>
</section>
<section id="portfolio">
  <div class="row">
    <div class="col-md-12">
      <h3>Exkluzívny rad zabudovaných spotrebičov pre najnáročnejších</h3>
      <br>
      <p>Jedinečný rad zabudovaných spotrebičov studioLine značky Siemens, obohatí nielen vašu kuchyňu, ale aj váš život. Exkluzívny
        design a inovatívna výbava spotrebičov ponúka nezameniteľný pôžitok nielen na pohľad, ale navyše aj absolútnu voľnosť
        pri všetkom, čo vám život prináša. Nechajte sa uniesť dobrodružstvom a zažite ešte viac výnimočných momentov – vďaka
        veľmi výkonným domácim spotrebičom máte k dispozícii čas celého sveta. Exkluzivita je u studioLine zaručená. Pre
        vás to znamená nekompromisnú kvalitu, špičkové poradenstvo a nadštandardný servis.</p>
    </div>
  </div>
</section>
<section id="portfolio" class="section product-section">
  <h2>Chladničky</h2>
  <a [routerLink]="['/ki86ssd40']">
    <app-productpanel productId="ki86ssd40" productImage="images/siemens/ki86ssd40/mcsa00495416-d7480-ki86ssd40-106541-def.jpg"
      productDesc="CoolEfficiency zabudovateľná kombinácia chladnička/mraznička s priehradkou hyperFresh plus pre až 2x dlhšie čerstvé ovocie a zeleninu v obzvlášť efektívnej energetickej triede A+++."
      productPrice=1439>
    </app-productpanel>
  </a>

  <a [routerLink]="['/KI87SSD30']">
    <app-productpanel productId="KI87SSD30" productImage="images/siemens/KI87SSD30/MCSA00495410_D7478_KI87SSD30_106536__def.jpg"
      productDesc="iQ500 coolEfficiency zabudovateľná kombinácia chladnička/mraznička ploché pánty, systém softClose (jemné dovieranie dverí)"
      productPrice=1135>
    </app-productpanel>
  </a>

  <a [routerLink]="['/CI36BP01']">
    <app-productpanel productId="CI36BP01" productImage="images/siemens/CI36BP01/01.jpg"
      productDesc="iQ700 noFrost, Side by Side, zabudovateľná chladnička zabudovateľná kombinácia chladnička/mraznička, (hore dvojdverová)"
      productPrice=4539>
    </app-productpanel>
  </a>

  <h2>Zásuvky</h2>
  <a [routerLink]="['/BV830ENB1']">
    <app-productpanel productId="BV830ENB1" productImage="images/siemens/bv830enb1/mcsa02050992-bv830enb1-vakuumierschublade-siemens-pga1-def.jpg"
      productDesc="Vákuovacia zásuvka: pre dlhotrvajúci pôžitok z chuti." productPrice=2259>
    </app-productpanel>
  </a>
  <a [routerLink]="['/BI830CNB1']">
    <app-productpanel productId="BI830CNB1" productImage="images/siemens/bi830cnb1/mcsa02109794-bi830cnb1-waermeschubladen-siemens-pga1-def.jpg"
      productDesc="Ohrevná zásuvka je perfektná pre predohriatie riadu, tanierov, pre udržiavanie teploty jedla a pre jemné a pomalé varenie."
      productPrice=569>
    </app-productpanel>
  </a>

  <h2>Konvenčná rúra</h2>
  <a [routerLink]="['/HB417GUB0']">
    <app-productpanel productId="HB417GUB0" productImage="images/siemens/hb417gub0/mcsa01974725-hb417gub0-siemens-stp-fullsizeoven--eox5-def.jpg"
      productDesc="Skráťte si čas varenia vďaka rýchlemu predohrevu." productPrice=755>
    </app-productpanel>
  </a>
  <a [routerLink]="['/HB478GUB0']">
    <app-productpanel productId="HB478GUB0" productImage="images/siemens//hb478gub0/mcsa01974724-hb478gcb0s-siemens-stp-fullsizeoven--eox5-def.jpg"
      productDesc="Skráťte si čas varenia vďaka funkcii coolStart, rýchlemu predohrevu a activeClean." productPrice=945>
    </app-productpanel>
  </a>
  <a [routerLink]="['/HB836GVB6']">
    <app-productpanel productId="HB836GVB6" productImage="images/siemens/hb836gvb6/mcsa01934796-1341455-hb836gvb6-def.jpg" productDesc="Skráťte si čas varenia pomocou funkcie predohrevu.Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect."
      productPrice=1059>
    </app-productpanel>
  </a>
  <a [routerLink]="['/HB876G8B6']">
    <app-productpanel productId="HB876G8B6" productImage="images/siemens/hb876g8b6/mcsa01934796-1341455-hb836gvb6-def.jpg" productDesc="Skráťte si čas varenia pomocou funkcie predohrevu a activeClean®. A majte rúru pod kontrolou odkiaľkoľvek vďaka Home Connect."
      productPrice=1849>
    </app-productpanel>
  </a>

  <h2>Mikrovlnné rúry</h2>
  <a [routerLink]="['/BF425LMB0']">
    <app-productpanel productId="BF425LMB0" productImage="images/siemens/bf425lmb0/mcsa02032272-bf425lmb0-compactoven-siemens-stp---eox5-def.jpg"
      productDesc="Zabudovateľná mikrovlnná rúra, výška 38cm, vhodná pre rozmrazovanie, ohrev a prípravu jedla v najkratšom možnom čase."
      productPrice=409>
    </app-productpanel>
  </a>
  <a [routerLink]="['/BF834LGB1']">
    <app-productpanel productId="BF834LGB1" productImage="images/siemens/bf834lgb1/mcsa01886140-1299376-bf834lgb1-def.jpg" productDesc="Zabudovateľná mikrovlnná rúra s výškou 38cm: pre rýchle rozmrazovanie, zohrievanie alebo prípravu jedla (v čo najkratšom možnom čase)."
      productPrice=755>
    </app-productpanel>
  </a>
  <a [routerLink]="['/BF834RGB1']">
    <app-productpanel productId="BF834RGB1" productImage="images/siemens/bf834rgb1/mcsa01886140-1299376-bf834lgb1-def.jpg" productDesc="Zabudovateľná mikrovlnná rúra s výškou 38cm: pre rýchle rozmrazovanie, zohrievanie alebo prípravu jedla (v čo najkratšom možnom čase)."
      productPrice=755>
    </app-productpanel>
  </a>
  <a [routerLink]="['/CM836GPB6']">
    <app-productpanel productId="CM836GPB6" productImage="images/siemens/cm836gpb6/mcsa01936108-1342355-cm836gpb6-def.jpg" productDesc="Skráťte si čas varenia vďaka varioSpeed a rýchlemu predohrevu. A nastavte si všetky užitočné funkcie pomocou Home Connect. Odkiaľkoľvek."
      productPrice=1589>
    </app-productpanel>
  </a>
  <a [routerLink]="['/HM876G2B6']">
    <app-productpanel productId="HM876G2B6" productImage="images/siemens/hm876g2b6/mcsa01935004-1341543-hm836gpb6-def.jpg" productDesc="Skráťte si čas varenia pomocou funkcie varioSpeed, predohrevu a activeClean®. A majte všetko pod kontrolou odkiaľkoľvek vďaka Home Connect."
      productPrice=1849>
    </app-productpanel>
  </a>
  <a [routerLink]="['/CF834AGB1']">
    <app-productpanel productId="CF834AGB1" productImage="images/siemens/CF834AGB1/01.jpg" productDesc="Zabudovateľná mikrovlnná rúra 45cm vysoká pre energeticky úsporné rozmrazovanie, zohrievanie, alebo pripravovanie jedla v najkrtašom možnom čase."
      productPrice=1019>
    </app-productpanel>
  </a>
  <a [routerLink]="['/CP465AGB0']">
    <app-productpanel productId="CP465AGB0" productImage="images/siemens/CP465AGB0/01.jpg" productDesc="45 cm vysoká mikrovlnná rúra s horúcim vzduchom a funkciou para šetrne pripravia vaše pokrmy v tom najkratšom možnom čase."
      productPrice=1169>
    </app-productpanel>
  </a>

  <h2>Rúra s parou</h2>
  <a [routerLink]="['/CS858GRB6']">
    <app-productpanel productId="CS858GRB6" productImage="images/siemens/cs858grb6/mcsa01936121-1342364-cs858grb6-def.jpg" productDesc="Skráťte si čas varenia pomocou funkcie predohrevu. Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect."
      productPrice=2259>
    </app-productpanel>
  </a>
  <a [routerLink]="['/HS836GVB6']">
    <app-productpanel productId="HS836GVB6" productImage="images/siemens/hs836gvb6/d01d40913e0cd98bfa217ae77c8f2370.jpg" productDesc="Skráťte si čas varenia pomocou funkcie predohrevu. Majte svoju rúru pod kontrolou odkiaľkoľvek vďaka Home Connect."
      productPrice=2039>
    </app-productpanel>
  </a>

  <a [routerLink]="['/CD834GBB1']">
    <app-productpanel productId="CD834GBB1" productImage="images/siemens/CD834GBB1/01.jpg" productDesc="Kompaktná parná rúra pre prípravu chutného, nízkotučného a zdravého jedla."
      productPrice=1129>
    </app-productpanel>
  </a>

  <h2>Indukčné varné dosky</h2>
  <a [routerLink]="['/EH877FVB1E']">
    <app-productpanel productId="EH877FVB1E" productImage="images/siemens/eh877fvb1e/mcsa01737752-eh877fvb1e-electrichob-siemens-stp-def.jpg"
      productDesc="Indukčný varný panel s inovatívnymi funkciami pre väčšiu flexibilitu pri varení." productPrice=945>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EH647FFB1E']">
    <app-productpanel productId="EH647FFB1E" productImage="images/siemens/eh647ffb1e/mcsa00998321-622181-eh647ffb1es-def.jpg"
      productDesc="Indukčný varný panel s inovatívanymi funkciami pre väčšiu flexibilitu pri varení." productPrice=719>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX607LYV1E']">
    <app-productpanel productId="EX607LYV1E" productImage="images/siemens/ex607lyv1e/mcsa01731648-ex607lyv1e-electrichob-siemens-stp-def.jpg"
      productDesc="Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení." productPrice=1099>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX677LYV1E']">
    <app-productpanel productId="EX677LYV1E" productImage="images/siemens/ex677lyv1e/mcsa01731773-ex677lyv1e-electrichob-siemens-stp-def.jpg"
      productDesc="Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení." productPrice=1099>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX807LYV1E']">
    <app-productpanel productId="EX807LYV1E" productImage="images/siemens/ex807lyv1e/mcsa01926230-ex807lyv1e-electrichob-siemens-stp-def.jpg"
      productDesc="Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení." productPrice=1249>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX877LYV1E']">
    <app-productpanel productId="EX877LYV1E" productImage="images/siemens/ex877lyv1e/mcsa01731708-ex877lyv1e-electrichob-siemens-stp-def.jpg"
      productDesc="Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení." productPrice=1249>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX907KXX1E']">
    <app-productpanel productId="EX907KXX1E" productImage="images/siemens/ex907kxx1e/mcsa01744547-ex907kxx1e-electrichob-siemens-stp-def.jpg"
      productDesc="Moderný indukčný varný panel so zónou flexIndukcia - pre viac flexibility pri varení." productPrice=1889>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EX807LX33E']">
    <app-productpanel productId="EX807LX33E" productImage="images/siemens/ex807lx33e/mcsa01759148-ex807lx33e-electrichob-siemens-stp-def.jpg"
      productDesc="Indukčný varný panel s integrovanou ventiláciou." productPrice=3289>
    </app-productpanel>
  </a>
  <a [routerLink]="['/EH875KU12E']">
    <app-productpanel productId="EH875KU12E" productImage="images/siemens/EH875KU12E/01.jpg"
      productDesc="Celoplošný indukčný varná panel - nová voľnosť pri varení." productPrice=3399>
    </app-productpanel>
  </a>

  <h2>Dekorativný odsávač</h2>
  <a [routerLink]="['/LC87KIM60S']">
    <app-productpanel productId="LC87KIM60S" productImage="images/siemens/lc87kim60s/mcsa01746201-stp-lc87kim60s-1183972-def.jpg"
      productDesc="Odsávač pár s nakloneným designom a vysokým výkonom odvetrávania." productPrice=945>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LC97FQP60S']">
    <app-productpanel productId="LC97FQP60S" productImage="images/siemens/lc97fqp60s/mcsa02004228-lc97fqp60s-decorative-siemens-stp-def.jpg"
      productDesc="Odsávač pár s plochým designom s vysokým výkonom." productPrice=1099>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LC91KWW60S']">
    <app-productpanel productId="LC91KWW60S" productImage="images/siemens/lc91kww60s/mcsa01747361-stp-lc91kww60s-1185170-def.jpg"
      productDesc="Odsávač pár s nakloneným designom a vysokým výkonom odvetrávania." productPrice=1509>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LC97FVW60S']">
    <app-productpanel productId="LC97FVW60S" productImage="images/siemens/lc97fvw60s/mcsa01747313-stp-lc97fvw60s-1185149-def.jpg"
      productDesc="Odsávač pár s plochým designom s vysokým výkonom." productPrice=1469>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LC98BA572S']">
    <app-productpanel productId="LC98BA572S" productImage="images/siemens/LC98BA572S/01.jpg"
      productDesc="Komínový odsávač pár pre perfektné umiestnenie na stenu do kuchyne." productPrice=875>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LF98BA572S']">
    <app-productpanel productId="LF98BA572S" productImage="images/siemens/LF98BA572S/01.jpg"
      productDesc="Ostrovčekový odsávač pár sa perfektne hodí nad všetky varné ostrovy, montáž na strop." productPrice=1129>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LC91BA582S']">
    <app-productpanel productId="LC91BA582S" productImage="images/siemens/LC91BA582S/01.jpg"
      productDesc="Komínový odsávač pár pre perfektné umiestnenie na stenu do kuchyne." productPrice=1099>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LF91BA582S']">
    <app-productpanel productId="LF91BA582S" productImage="images/siemens/LF91BA582S/01.jpg"
      productDesc="Ostrovčekový odsávač pár sa perfektne hodí nad všetky varné ostrovy, montáž na strop." productPrice=1399>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LD97AA670']">
    <app-productpanel productId="LD97AA670" productImage="images/siemens/LD97AA670/01.jpg"
      productDesc="Elegantný a decentný downdraft - spodný odťah za varným panelom je možné ľahko riešiť na odťah von v prípade potreby." productPrice=2079>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LF159RF50']">
    <app-productpanel productId="LF159RF50" productImage="images/siemens/LF159RF50/01.jpg"
      productDesc="Priestor šetriaci stropný odsávač pár pre perfektné zabudovanie do stropu." productPrice=2409>
    </app-productpanel>
  </a>

  <h2>Zabudovateľný odsávač</h2>
  <a [routerLink]="['/LI67SA560S']">
    <app-productpanel productId="LI67SA560S" productImage="images/siemens/li67sa560s/mcsa02107991-li67sa560s-telescopic-hood-siemens-stp-def.jpg"
      productDesc="Plochý výsuvný odsávač pár s vysokým výkonom a takmer s neviditeľným umiestnením do skrinky." productPrice=749>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LI97SA560S']">
    <app-productpanel productId="LI97SA560S" productImage="images/siemens/li97sa560s/mcsa02188742-li97sa560s-telescopic-hood-siemens-stp-def.jpg"
      productDesc="Plochý výsuvný odsávač pár s vysokým výkonom a takmer s neviditeľným umiestnením do skrinky." productPrice=905>
    </app-productpanel>
  </a>
  <a [routerLink]="['/LF959RB51']">
    <app-productpanel productId="LF959RB51" productImage="images/siemens/LF959RB51/01.jpg"
      productDesc="Ostrovčekový odsávač pár sa perfektne hodí nad všetky varné ostrovy, montáž na strop." productPrice=1489>
    </app-productpanel>
  </a>

  <h2>Umývačky riadu</h2>
  <a [routerLink]="['/SN836X00PE']">
    <app-productpanel productId="SN836X00PE" productImage="images/siemens/sn836x00pe/mcsa01765151-g6536-sn836x00pe-1200068-def.jpg"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere"
      productPrice=909>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SX836X00PE']">
    <app-productpanel productId="SX836X00PE" productImage="images/siemens/sx836x00pe/mcsa01765161-g6541-sx836x00pe-1200073-corr-def.jpg"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere."
      productPrice=949>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SN858D00PE']">
    <app-productpanel productId="SN858D00PE" productImage="images/siemens/sn858d00pe/mcsa01765153-g6537-sn858d00pe-1200069-def.jpg"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere."
      productPrice=1059>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SX858D00PE']">
    <app-productpanel productId="SX858D00PE" productImage="images/siemens/sx858d00pe/mcsa01765163-g6542-sx858d00pe-1200074-def.jpg"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere."
      productPrice=1099>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SN858D06PE']">
    <app-productpanel productId="SN858D06PE" productImage="images/siemens/sn858d06pe/mcsa01765155-g6538-sn858d06pe-1200070-def.png"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere."
      productPrice=1215>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SN878D26PE']">
    <app-productpanel productId="SN878D26PE" productImage="images/siemens/sn878d26pe/mcsa01765157-g6539-sn878d26pe-1200071-def.jpg"
      productDesc="brilliantShine system so sušením Zeolith® pre dosiahnutie výnimočne žiarivého a suchého skla; varioSpeed Plus zabezpečí až 3x rýchlejšie umývanie a sušenie."
      productPrice=1439>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SX878D26PE']">
    <app-productpanel productId="SX878D26PE" productImage="images/siemens/sx878d26pe/mcsa01756191-g6204-sx878d26pe-1193198-def.jpg"
      productDesc="brilliantShine system so systémom sušenia Zeolith® pre dosiahnutie výnimočne žiarivého a suchého skla; varioSpeed Plus zabezpečí až 3x rýchlejšie umývanie a sušenie."
      productPrice=1479>
    </app-productpanel>
  </a>
  <a [routerLink]="['/SR856D00PE']">
    <app-productpanel productId="SR856D00PE" productImage="images/siemens/sr856d00pe/mcsa02084448-h4973-sr856d00pe-1474032-cmyk-def.jpg"
      productDesc="varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere."
      productPrice=759>
    </app-productpanel>
  </a>

  <h2>Kávovary</h2>
  <a [routerLink]="['/CT836LEB6']">
    <app-productpanel productId="CT836LEB6" productImage="images/siemens/ct836leb6/mcsa01952305-ct836leb6-buildin-coffee-siemens-stp-def.jpg"
      productDesc="Zabudovateľný plnoautomatický kávovar s Home Connect pre perfektný pôžitok s najvyšším komfortom." productPrice=2299>
    </app-productpanel>
  </a>
</section>