import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-SN878D26PE",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class SN878D26PEComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "SN878D26PE";
    this._imagePath =
      "images/siemens/sn878d26pe/mcsa01765157-g6539-sn878d26pe-1200071-def.jpg";
    this._headerText =
      "brilliantShine system so sušením Zeolith® pre dosiahnutie výnimočne žiarivého a suchého skla; varioSpeed Plus zabezpečí až 3x rýchlejšie umývanie a sušenie.";
    this._productPrice = 1439;
    this._productDesc = "../../../ProductDesc/SN878D26PE.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/sn878d26pe/01.jpg");    
      this.setImages(1, '', "images/siemens/sn878d26pe/02.jpg");    
      this.setImages(2, '', "images/siemens/sn878d26pe/03.jpg");
      this.setImages(3, '', "images/siemens/sn878d26pe/04.jpg");
      this.setImages(4, '', "images/siemens/sn878d26pe/05.jpg");
      this.setImages(5, '', "images/siemens/sn878d26pe/06.jpg");
      this.setImages(6, '', "images/siemens/sn878d26pe/07.jpg");
  }
}
