import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-CF834AGB1",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class CF834AGB1Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "CF834AGB1";
    this._imagePath =
      "images/siemens/CF834AGB1/01.jpg";
    this._headerText =
      "Zabudovateľná mikrovlnná rúra 45cm vysoká pre energeticky úsporné rozmrazovanie, zohrievanie, alebo pripravovanie jedla v najkrtašom možnom čase.";
    this._productPrice = 1019;
    this._productDesc = "../../../ProductDesc/CF834AGB1.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/CF834AGB1/01.jpg");    
      this.setImages(1, '', "images/siemens/CF834AGB1/02.jpg");    
      this.setImages(2, '', "images/siemens/CF834AGB1/03.jpg");
      this.setImages(3, '', "images/siemens/CF834AGB1/04.jpg");
      this.setImages(4, '', "images/siemens/CF834AGB1/05.jpg");
      this.setImages(5, '', "images/siemens/CF834AGB1/06.jpg");
  }
}