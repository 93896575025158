import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-EH877FVB1E",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class EH877FVB1EComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "EH877FVB1E";
    this._imagePath =
      "images/siemens/eh877fvb1e/mcsa01737752-eh877fvb1e-electrichob-siemens-stp-def.jpg";
    this._headerText =
      "Indukčný varný panel s inovatívnymi funkciami pre väčšiu flexibilitu pri varení.";
    this._productPrice = 945;
    this._productDesc = "../../../ProductDesc/EH877FVB1E.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/eh877fvb1e/01.jpg");    
      this.setImages(1, '', "images/siemens/eh647ffb1e/02.jpg");    
      this.setImages(2, '', "images/siemens/eh647ffb1e/03.jpg");
      this.setImages(3, '', "images/siemens/eh647ffb1e/04.jpg");
      this.setImages(4, '', "images/siemens/eh647ffb1e/05.jpg");
      this.setImages(5, '', "images/siemens/eh647ffb1e/06.jpg");
      this.setImages(6, '', "images/siemens/eh647ffb1e/07.jpg");
      this.setImages(7, '', "images/siemens/eh647ffb1e/08.jpg");
      this.setImages(8, '', "images/siemens/eh647ffb1e/09.jpg");
  }
}
