import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-ki86ssd40",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class Ki86ssd40Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "ki86ssd40";
    this._imagePath =
      "images/siemens/ki86ssd40/mcsa00495416-d7480-ki86ssd40-106541-def.jpg";
    this._headerText =
      "coolEfficiency zabudovateľná kombinácia chladnička/mraznička s priehradkou hyperFresh plus pre až 2x dlhšie čerstvé ovocie a zeleninu v obzvlášť efektívnej energetickej triede A+++.";
    this._productPrice = 1439;
    this._productDesc = "../../../ProductDesc/ki86ssd40.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/ki86ssd40/01.jpg");    
      this.setImages(1, '', "images/siemens/ki86ssd40/02.jpg");    
      this.setImages(2, '', "images/siemens/ki86ssd40/03.jpg");
      this.setImages(3, '', "images/siemens/ki86ssd40/04.jpg");
      this.setImages(4, '', "images/siemens/ki86ssd40/05.jpg");
      this.setImages(5, '', "images/siemens/ki86ssd40/06.jpg");
  }
}
 