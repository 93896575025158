<app-header></app-header>
<section class="section product-detail-section">
  <div class="row">
    <div class="col-md-3">
      <div class="product-detail-image">
        <img class="img-responsive" src={{_imagePath}} />
      </div>
    </div>
    <div class="col-md-9">
      <h3>{{_productId}}</h3>
      <p>{{_headerText}}</p>
      <p class="product-detail-price">{{_productPrice}} €</p>
      <div id="product-detail-desc"></div>
    </div>
  </div>
</section>

<section class="section">
  <div class="row">
    <div class="product-image-section">
      <ng-container *ngFor="let img of images">
        <div class="col-md-2">
          <div class="product-detail-border">
            <img [src]="img.modal.img" (click)="openImageModalRowDescription(img)" class="img-responsive" />
            <p class="kitchen-type">{{img.modal.description}}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<ks-plain-gallery [id]="8" [images]="images"></ks-plain-gallery>