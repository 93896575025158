import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nastiahnutie',
  templateUrl: './nastiahnutie.component.html',
  styleUrls: ['./nastiahnutie.component.css']
})
export class NastiahnutieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
