import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-SN858D00PE",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class SN858D00PEComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "SN858D00PE";
    this._imagePath =
      "images/siemens/sn858d00pe/mcsa01765153-g6537-sn858d00pe-1200069-def.jpg";
    this._headerText =
      "varioSpeed Plus pre až 3x rýchlejšie umývanie a sušenie: inovatívne technológie pre inteligentné používanie a dokonalosť v každom smere.";
    this._productPrice = 1059;
    this._productDesc = "../../../ProductDesc/SN858D00PE.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      
      this.setImages(0, '', "images/siemens/sn858d00pe/01.jpg");    
      this.setImages(1, '', "images/siemens/sn858d00pe/02.jpg");    
      this.setImages(2, '', "images/siemens/sn858d00pe/03.jpg");
      this.setImages(3, '', "images/siemens/sn858d00pe/04.jpg");
      this.setImages(4, '', "images/siemens/sn858d00pe/05.jpg");
      this.setImages(5, '', "images/siemens/sn858d00pe/06.jpg");
      this.setImages(6, '', "images/siemens/sn858d00pe/07.jpg");
  }
}
