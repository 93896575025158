<section id="portfolio">
    <div class="row">
      <div class="col-md-12">
        <img src="images/Bosch/Bosch_sk.png" class="img-responsive" />
      </div>
      <div class="col-md-12">
        <p class="text-justify">
          Kvalita bez kompromisov overená každodenným životom. Domáce spotrebiče
          Bosch vám prinášajú technickú bezchybnosť a spoľahlivosť, vďaka ktorej
          vám uľahčia deň a na dlhé roky vás zbavia starostí. Navyše všetky
          zabudovateľné spotrebiče sa takmer neviditeľne začlenia do každej
          kuchyne. Jedno však neprehliadnete: typickú 100% kvalitu, výkon a
          komfort značky Bosch.
        </p>
      </div>
      <div class="col-md-12">
        <h2>Chladenie</h2>
        <p class="text-justify">
          Chladiace a mraziace spotrebiče Bosch sú garanciou vždy čerstvých
          potravín i najúspornejších energetických tried. Jedným slovom
          efektívne. S chladničkami Bosch je každodenný život chutnejší a
          jednoduchší. Vďaka dokonalému osvetleniu, prehľadným poličkám,
          priestranným boxom a dôvtipným oddeľovačom vždy presne viete, aké
          potraviny máte uskladnené.
        </p>
      </div>
      <div class="col-md-3"><img src="images\Bosch\Chlazení\1.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Chlazení\2.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Chlazení\3.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Chlazení\4.jpg" class="img-responsive" /></div>
      <div class="col-md-12">
        <h2>Umývanie riadu</h2>
        <p class="text-justify">
          Hľadáte umývačku riadu, ktorá nielen bezchybne umýva, ale vyniká aj
          inovatívnymi technológiami a pohodlným ovládaním? Umývačky Bosch vám
          zaručia, že riad budete mať nielen perfektne čistý, ale okrem toho,
          vám pomôžu ušetriť náklady na elektrinu a znížiť spotrebu vody.
        </p>
      </div>
      <div class="col-md-3"><img src="images\Bosch\Myčky\1.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Myčky\2.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Myčky\3.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Myčky\4.jpg" class="img-responsive" /></div>
      <div class="col-md-12">
        <h2>Odsávače</h2>
        <p class="text-justify">
          Odsávač je len vtedy perfektný, ak zapadne do vašej kuchyne: z
          hľadiska dizajnu, funkčnosti a požiadaviek. To je dôvod, prečo vám
          Bosch ponúka rôzne typy a konštrukčné rady odsávačov pár. Aby ste pri
          varení cítili len to, čo naozaj cítiť chcete, sú odsávače pár obzvlášť
          výkonné. Okrem toho je ich chod tak tichý, že takmer nič nepočujete.
        </p>
      </div>
      <div class="col-md-4"><img src="images\Bosch\Odsavače\1.jpg" class="img-responsive" /></div>
      <div class="col-md-4"><img src="images\Bosch\Odsavače\2.jpg" class="img-responsive" /></div>
      <div class="col-md-4"><img src="images\Bosch\Odsavače\5.jpg" class="img-responsive" /></div>
      <div class="col-md-12">
        <h2>Rúry na pečenie</h2>
        <p class="text-justify">
          Rúry Bosch sú vyrobené z tých najstabilnejších materiálov a ich
          životnosť je testovaná v náročných záťažových testoch. Perfektné
          výsledky pečenia sú teda samozrejmosťou. Objavte premyslené detaily,
          ktoré vám pomôžu pripraviť dokonalé pokrmy.
        </p>
      </div>
      <div class="col-md-3"><img src="images\Bosch\Trouby\1.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Trouby\2.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Trouby\3.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Trouby\4.jpg" class="img-responsive" /></div>
      <div class="col-md-12">
        <h2>Varné dosky</h2>
        <p class="text-justify">
          Varná doska je dušou kuchyne. Aby bola podľa vašich predstáv, sú varné
          dosky vybavené radom praktických pomocníkov, ktorí vám pri smažení a
          varení ušetria veľa práce. Vďaka jednoduchému ovládaniu a premysleným
          funkciám sa úspech stopercentne dostaví. A navyše v kombinácii s
          úžasným dizajnom.
        </p>
      </div>
      <div class="col-md-3"><img src="images\Bosch\Varné_desky\1.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Varné_desky\2.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Varné_desky\3.jpg" class="img-responsive" /></div>
      <div class="col-md-3"><img src="images\Bosch\Varné_desky\4.jpg" class="img-responsive" /></div>
    </div>
</section>
