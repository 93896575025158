<app-header></app-header>
<section id="FirstText" class="section FirstText">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="FirstText-content">
                 <h3>VŠEOBECNÉ POKYNY PRE ÚDRŽBU KUCHYNÍ BALLERINA</h3>
                 <p align="justify">Aby ste sa mohli dlho tešit zo svojej kuchyne Ballerina, je treba venovať pozornosť pravidelnej údržbe a správnemu čisteniu. Zásadne platí: odstraňujte vzniknuté znečistenie kuchyne okamžite. Nepoužívajte žiadné brusné pasty, alebo prostriedky obsahujuce kyseliny či iné agresivne látky, ale len běžné saponáty či mydlovú vodu. Veľmi dôležité je tiež nepoužívať mikrofázové utierky, které fungujú ako šmirgel! Detailné návody na čistenie kuchyne Ballerina sú k stiahnutiu k dispozicií tu:</p>
                 <p><a href="Manuals\Navod-na-udrzbu-vysoce-lesklych-dvirek-z-akrylu.pdf" target="_blank">Návod na údržbu vysoce lesklých dvířek z akrylu</a></p>
                <p><a href="Manuals\Navod-na-udrzbu-drevenych-dvirek-ke-stazeni.pdf" target="_blank">Návod na údržbu dřevěných dvířek</a></p>
                <p><a href="Manuals\Navod-na-udrzbu-dvirek-s-umelym-povrchem-ke-stazeni.doc.pdf" target="_blank">Návod na údržbu dvířek s umělým povrchem</a></p>                        
                <p><a href="Manuals\Navod-na-udrzbu-dvirek-ve vysokem-lesku-ke-stazeni.pdf" target="_blank">Návod na údržbu dvířek ve vysokém lesku</a></p>                        
                <p><a href="Manuals\Navod-na-udrzbu-sametovych-matnych-dvirek-ke-stazeni.pdf" target="_blank">Návod na údržbu sametových matných dvířek</a></p>                      
                <p><a href="Manuals\Navod-na-udrzby-pracovnich-desek.pdf" target="_blank">Návod na údržbu pracovních desek</a></p>                        
                <p><a href="Manuals\Navod-na-udzbu-madel-ke-stazeni.pdf" target="_blank">Návod na údržbu madel</a></p>                        
                <p><a href="Manuals\Pokyny-pro-udrzbu-umeleho-povrchu-ve-vysokem-lesku.pdf" target="_blank">Pokyny pro údržbu umělého povrchu ve vysokém lesku</a></p>                        
                <p><a href="Manuals\Vseobecne-pokyny-pro-udrzbu.pdf" target="_blank">Všeobecné pokyny pro údržbu</a></p> 
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="FirstText-content">
                    <h3>NA STIAHNUTIE</h3>
                    <h3> </h3>
                    <p><a href="https://www.ballerina.de/downloads/kuechen-journal" target="_blank">Katalog Ballerina 1</a></p>
                    <p><a href="https://www.ballerina.de/downloads/ballerina-avantgarde" target="_blank">Katalog Ballerina 2</a></p>
                    <p><a href="https://www.ballerina.de/downloads/ballerina-mehrwert" target="_blank">Katalog Ballerina 3</a></p>
                    <p><a href="https://www.ballerina.de/downloads/energielabel-kuechenleuchten" target="_blank">Energetické štítky</a></p>
                </div>
            </div>
            </div>
        </div>
</section>
