import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LI97SA560S",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LI97SA560SComponent extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "";
    this._imagePath =
      "images/siemens/li97sa560s/mcsa02188742-li97sa560s-telescopic-hood-siemens-stp-def.jpg";
    this._headerText =
      "Plochý výsuvný odsávač pár s vysokým výkonom a takmer s neviditeľným umiestnením do skrinky.";
    this._productPrice = 905;
    this._productDesc = "../../../ProductDesc/LI97SA560S.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/li97sa560s/01.jpg");    
      this.setImages(1, '', "images/siemens/li97sa560s/02.jpg");    
      this.setImages(2, '', "images/siemens/li97sa560s/03.jpg");
      this.setImages(3, '', "images/siemens/li97sa560s/04.jpg");
      this.setImages(4, '', "images/siemens/li97sa560s/05.jpg");
      this.setImages(5, '', "images/siemens/li97sa560s/06.jpg");
      this.setImages(6, '', "images/siemens/li97sa560s/07.jpg");
      this.setImages(7, '', "images/siemens/li97sa560s/08.jpg");
      this.setImages(8, '', "images/siemens/li97sa560s/09.jpg");
      this.setImages(9, '', "images/siemens/li97sa560s/10.jpg");
      this.setImages(10, '', "images/siemens/li97sa560s/11.jpg");
  }
}
