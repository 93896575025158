import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { IntroComponent } from "./intro/intro.component";
import { HeaderComponent } from "../shared/header/header.component";
import { KuchynebuttonsComponent } from "../shared/kuchynebuttons/kuchynebuttons.component";

import { ModerneComponent } from "./kuchynelist/moderne/moderne.component";
import { CountryComponent } from "./kuchynelist/country/country.component";
import { ClassicComponent } from "./kuchynelist/classic/classic.component";
import { ComfortComponent } from "./kuchynelist/comfort/comfort.component";
import { RobusComponent } from "./kuchynelist/robus/robus.component";
import { Line45Component } from "./kuchynelist/line45/line45.component";
import { BlacklineComponent } from "./kuchynelist/blackline/blackline.component";
import { YkitchenComponent } from "./kuchynelist/ykitchen/ykitchen.component";

import "hammerjs";
import "mousetrap";
import { GalleryModule } from '@ks89/angular-modal-gallery'; 
import { PortfolioComponent } from "../shared/portfolio/portfolio.component";
import { StudiolineComponent } from "./studiolinecategories/studioline.component";
import { ProductpanelComponent } from "../shared/productpanel/productpanel.component";
import { NastiahnutieComponent } from "./nastiahnutie/nastiahnutie.component";
import { ProductdetailComponent } from "./productdetail/productdetail.component";

import { Ki86ssd40Component } from "./products/studioline/ki86ssd40.component";
import { BV830ENB1Component } from "./products/studioline/BV830ENB1.component";
import { BI830CNB1Component } from "./products/studioline/BI830CNB1.component";
import { HB417GUB0Component } from "./products/studioline/HB417GUB0.component";
import { HB478GUB0Component } from "./products/studioline/HB478GUB0.component";
import { HB836GVB6Component } from "./products/studioline/HB836GVB6.component";
import { HB876G8B6Component } from "./products/studioline/HB876G8B6.component";
import { BF425LMB0Component } from "./products/studioline/BF425LMB0.component";
import { BF834LGB1Component } from "./products/studioline/BF834LGB1.component";
import { BF834RGB1Component } from "./products/studioline/BF834RGB1.component";
import { CM836GPB6Component } from "./products/studioline/CM836GPB6.component";
import { HM876G2B6Component } from "./products/studioline/HM876G2B6.component";
import { CS858GRB6Component } from "./products/studioline/CS858GRB6.component";
import { HS836GVB6Component } from "./products/studioline/HS836GVB6.component";
import { EH877FVB1EComponent } from "./products/studioline/EH877FVB1E.component";
import { EH647FFB1EComponent } from "./products/studioline/EH647FFB1E.component";
import { EX607LYV1EComponent } from "./products/studioline/EX607LYV1E.component";
import { EX677LYV1EComponent } from "./products/studioline/EX677LYV1E.component";
import { EX807LYV1EComponent } from "./products/studioline/EX807LYV1E.component";
import { EX877LYV1EComponent } from "./products/studioline/EX877LYV1E.component";
import { EX907KXX1EComponent } from "./products/studioline/EX907KXX1E.component";
import { EX807LX33EComponent } from "./products/studioline/EX807LX33E.component";
import { LC87KIM60SComponent } from "./products/studioline/LC87KIM60S.component";
import { LC97FQP60SComponent } from "./products/studioline/LC97FQP60S.component";
import { LC91KWW60SComponent } from "./products/studioline/LC91KWW60S.component";
import { LC97FVW60SComponent } from "./products/studioline/LC97FVW60S.component";
import { LI67SA560SComponent } from "./products/studioline/LI67SA560S.component";
import { LI97SA560SComponent } from "./products/studioline/LI97SA560S.component";
import { SN836X00PEComponent } from "./products/studioline/SN836X00PE.component";
import { SX836X00PEComponent } from "./products/studioline/SX836X00PE.component";
import { SN858D00PEComponent } from "./products/studioline/SN858D00PE.component";
import { SX858D00PEComponent } from "./products/studioline/SX858D00PE.component";
import { SN858D06PEComponent } from "./products/studioline/SN858D06PE.component";
import { SN878D26PEComponent } from "./products/studioline/SN878D26PE.component";
import { SX878D26PEComponent } from "./products/studioline/SX878D26PE.component";
import { SR856D00PEComponent } from "./products/studioline/SR856D00PE.component";
import { CT836LEB6Component } from "./products/studioline/CT836LEB6.component";
import { KI87SSD30Component } from "./products/studioline/KI87SSD30.component";
import { CI36BP01Component } from "./products/studioline/CI36BP01.component";
import { CD834GBB1Component } from "./products/studioline/CD834GBB1.component";
import { CF834AGB1Component } from "./products/studioline/CF834AGB1.component";
import { CP465AGB0Component } from "./products/studioline/CP465AGB0.component";
import { EH875KU12EComponent } from "./products/studioline/EH875KU12E.component";
import { LC98BA572SComponent } from "./products/studioline/LC98BA572S.component";
import { LF98BA572SComponent } from "./products/studioline/LF98BA572S.component";
import { LC91BA582SComponent } from "./products/studioline/LC91BA582S.component";
import { LF91BA582SComponent } from "./products/studioline/LF91BA582S.component";
import { LF959RB51Component } from "./products/studioline/LF959RB51.component";
import { LD97AA670Component } from "./products/studioline/LD97AA670.component";
import { LF159RF50Component } from "./products/studioline/LF159RF50.component";
import { StudiolinepreviewComponent } from './studiolinepreview/studiolinepreview.component';
import { GooglestreetComponent } from './googlestreet/googlestreet.component';
import { HcSiemensComponent } from './hc-siemens/hc-siemens.component';
import { HcBoschComponent } from './hc-bosch/hc-bosch.component';
import { BoschComponent } from './bosch/bosch.component';
import { BoraComponent } from './bora/bora.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    HeaderComponent,
    KuchynebuttonsComponent,
    ModerneComponent,
    CountryComponent,
    ClassicComponent,
    ComfortComponent,
    RobusComponent,
    Line45Component,
    BlacklineComponent,
    YkitchenComponent,
    PortfolioComponent,
    StudiolineComponent,
    ProductpanelComponent,
    NastiahnutieComponent,
    ProductdetailComponent,
    Ki86ssd40Component,
    BV830ENB1Component,
    BI830CNB1Component,
    HB417GUB0Component,
    HB478GUB0Component,
    HB836GVB6Component,
    HB876G8B6Component,
    BF425LMB0Component,
    BF834LGB1Component,
    BF834RGB1Component,
    CM836GPB6Component,
    HM876G2B6Component,
    CS858GRB6Component,
    HS836GVB6Component,
    EH877FVB1EComponent,
    EH647FFB1EComponent,
    EX607LYV1EComponent,
    EX677LYV1EComponent,
    EX807LYV1EComponent,
    EX877LYV1EComponent,
    EX907KXX1EComponent,
    EX807LX33EComponent,
    LC87KIM60SComponent,
    LC97FQP60SComponent,
    LC91KWW60SComponent,
    LC97FVW60SComponent,
    LI67SA560SComponent,
    LI97SA560SComponent,
    SN836X00PEComponent,
    SX836X00PEComponent,
    SN858D00PEComponent,
    SX858D00PEComponent,
    SN858D06PEComponent,
    SN878D26PEComponent,
    SX878D26PEComponent,
    SR856D00PEComponent,
    CT836LEB6Component,
    KI87SSD30Component,
    CI36BP01Component,
    CD834GBB1Component,
    CF834AGB1Component,
    CP465AGB0Component,
    EH875KU12EComponent,
    LC98BA572SComponent,
    LF98BA572SComponent,
    LC91BA582SComponent,
    LF91BA582SComponent,
    LF959RB51Component,
    LD97AA670Component,
    LF159RF50Component,
    StudiolinepreviewComponent,
    GooglestreetComponent,
    HcSiemensComponent,
    HcBoschComponent,
    BoschComponent,
    BoraComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    GalleryModule,
    RouterModule.forRoot(
      [
        { path: "intro", component: IntroComponent },
        { path: "moderne", component: ModerneComponent },
        { path: "country", component: CountryComponent },
        { path: "classic", component: ClassicComponent },
        { path: "comfort", component: ComfortComponent },
        { path: "robus", component: RobusComponent },
        { path: "line45", component: Line45Component },
        { path: "blackline", component: BlacklineComponent },
        { path: "ykitchen", component: YkitchenComponent },
        { path: "studiolinepreview", component: StudiolinepreviewComponent },
        { path: "studioline", component: StudiolineComponent },
        { path: "nastiahnutie", component: NastiahnutieComponent },
        { path: "hc-siemens", component: HcSiemensComponent },
        { path: "hc-bosch", component: HcBoschComponent },
        { path: "bosch", component: BoschComponent },
        { path: "bora", component: BoraComponent },

        { path: "ki86ssd40", component: Ki86ssd40Component },
        { path: "BV830ENB1", component: BV830ENB1Component },
        { path: "BI830CNB1", component: BI830CNB1Component },
        { path: "HB417GUB0", component: HB417GUB0Component },
        { path: "HB478GUB0", component: HB478GUB0Component },
        { path: "HB836GVB6", component: HB836GVB6Component },
        { path: "HB876G8B6", component: HB876G8B6Component },
        { path: "BF425LMB0", component: BF425LMB0Component },
        { path: "BF834LGB1", component: BF834LGB1Component },
        { path: "BF834RGB1", component: BF834RGB1Component },
        { path: "CM836GPB6", component: CM836GPB6Component },
        { path: "HM876G2B6", component: HM876G2B6Component },
        { path: "CS858GRB6", component: CS858GRB6Component },
        { path: "HS836GVB6", component: HS836GVB6Component },
        { path: "EH877FVB1E", component: EH877FVB1EComponent },
        { path: "EH647FFB1E", component: EH647FFB1EComponent },
        { path: "EX607LYV1E", component: EX607LYV1EComponent },
        { path: "EX677LYV1E", component: EX677LYV1EComponent },
        { path: "EX807LYV1E", component: EX807LYV1EComponent },
        { path: "EX877LYV1E", component: EX877LYV1EComponent },
        { path: "EX907KXX1E", component: EX907KXX1EComponent },
        { path: "EX807LX33E", component: EX807LX33EComponent },
        { path: "LC87KIM60S", component: LC87KIM60SComponent },
        { path: "LC97FQP60S", component: LC97FQP60SComponent },
        { path: "LC91KWW60S", component: LC91KWW60SComponent },
        { path: "LC97FVW60S", component: LC97FVW60SComponent },
        { path: "LI67SA560S", component: LI67SA560SComponent },
        { path: "LI97SA560S", component: LI97SA560SComponent },
        { path: "SN836X00PE", component: SN836X00PEComponent },
        { path: "SX836X00PE", component: SX836X00PEComponent },
        { path: "SN858D00PE", component: SN858D00PEComponent },
        { path: "SX858D00PE", component: SX858D00PEComponent },
        { path: "SN858D06PE", component: SN858D06PEComponent },
        { path: "SN878D26PE", component: SN878D26PEComponent },
        { path: "SX878D26PE", component: SX878D26PEComponent },
        { path: "SR856D00PE", component: SR856D00PEComponent },
        { path: "CT836LEB6", component: CT836LEB6Component },
        { path: "KI87SSD30", component: KI87SSD30Component },
        { path: "CI36BP01", component: CI36BP01Component },
        { path: "CD834GBB1", component: CD834GBB1Component },
        { path: "CF834AGB1", component: CF834AGB1Component },
        { path: "CP465AGB0", component: CP465AGB0Component },
        { path: "EH875KU12E", component: EH875KU12EComponent },
        { path: "LC98BA572S", component: LC98BA572SComponent },
        { path: "LF98BA572S", component: LF98BA572SComponent },
        { path: "LC91BA582S", component: LC91BA582SComponent },
        { path: "LF91BA582S", component: LF91BA582SComponent },
        { path: "LF959RB51", component: LF959RB51Component },
        { path: "LD97AA670", component: LD97AA670Component },
        { path: "LF159RF50", component: LF159RF50Component },

        { path: "", redirectTo: "intro", pathMatch: "full" },
        { path: "**", redirectTo: "intro", pathMatch: "full" }
      ],
      { useHash: true }
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
