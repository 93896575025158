import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-HB417GUB0",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class HB417GUB0Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "HB417GUB0";
    this._imagePath =
      "images/siemens/hb417gub0/mcsa01974725-hb417gub0-siemens-stp-fullsizeoven--eox5-def.jpg";
    this._headerText =
      "Skráťte si čas varenia vďaka rýchlemu predohrevu.";
    this._productPrice = 755;
    this._productDesc = "../../../ProductDesc/HB417GUB0.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/hb417gub0/01.jpg");    
      this.setImages(1, '', "images/siemens/hb417gub0/02.jpg");    
      this.setImages(2, '', "images/siemens/hb417gub0/03.jpg");
      this.setImages(3, '', "images/siemens/hb417gub0/04.jpg");
      this.setImages(4, '', "images/siemens/hb417gub0/05.jpg");
      this.setImages(5, '', "images/siemens/hb417gub0/06.jpg");
      this.setImages(6, '', "images/siemens/hb417gub0/07.jpg");
      this.setImages(7, '', "images/siemens/hb417gub0/08.jpg");
  }
}
