import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LF959RB51",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LF959RB51Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LF959RB51";
    this._imagePath =
      "images/siemens/LF959RB51/01.jpg";
    this._headerText =
      "Ostrovčekový odsávač pár sa perfektne hodí nad všetky varné ostrovy, montáž na strop.";
    this._productPrice = 1489;
    this._productDesc = "../../../ProductDesc/LF959RB51.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/LF959RB51/01.jpg");    
      this.setImages(1, '', "images/siemens/LF959RB51/02.jpg");    
      this.setImages(2, '', "images/siemens/LF959RB51/03.jpg");
      this.setImages(3, '', "images/siemens/LF959RB51/04.jpg");
      this.setImages(4, '', "images/siemens/LF959RB51/05.jpg");
      this.setImages(5, '', "images/siemens/LF959RB51/06.jpg");
      this.setImages(6, '', "images/siemens/LF959RB51/07.jpg");
      this.setImages(7, '', "images/siemens/LF959RB51/08.jpg");
      this.setImages(8, '', "images/siemens/LF959RB51/09.jpg");
  }
}