import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-BF425LMB0",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class BF425LMB0Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "BF425LMB0";
    this._imagePath =
      "images/siemens/bf425lmb0/mcsa02032272-bf425lmb0-compactoven-siemens-stp---eox5-def.jpg";
    this._headerText =
      "Zabudovateľná mikrovlnná rúra, výška 38cm, vhodná pre rozmrazovanie, ohrev a prípravu jedla v najkratšom možnom čase.";
    this._productPrice = 409;
    this._productDesc = "../../../ProductDesc/BF425LMB0.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/bf425lmb0/01.jpg");    
      this.setImages(1, '', "images/siemens/bf425lmb0/02.jpg");    
      this.setImages(2, '', "images/siemens/bf425lmb0/03.jpg");    
      this.setImages(3, '', "images/siemens/bf425lmb0/04.jpg");    
      this.setImages(4, '', "images/siemens/bf425lmb0/05.jpg");    
   
  }
}
