import { Component, OnInit, Input } from '@angular/core';
import {Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  showActiveClassIn1: string = "";
  showActiveClassIn4: string = "";
  constructor(private _router: Router) { }

  ngOnInit() {
      if (this._router.url === '/studioline' || this._router.url === '/studiolinepreview' || this._router.url === '/hc-siemens'){
         this.showActiveClassIn1 = "";
         this.showActiveClassIn4 = "active";
      }
      else
      {
        this.showActiveClassIn1 = "active";
        this.showActiveClassIn4 = "";
      }

  }

}
