import {
  Description, DescriptionStrategy,Image, 
} from '@ks89/angular-modal-gallery';

export abstract class GalleryBase{

      images: Array<Image>=[];
      openImageModalRowDescription(image: Image) {
        const index: number = this.getCurrentIndexCustomLayout(image, this.images);
      }

      private getCurrentIndexCustomLayout(image: Image, images: Array<Image>): number {
        return image ? images.indexOf(image) : -1;
      }
      
      customDescription: Description = {
        strategy: DescriptionStrategy.HIDE_IF_EMPTY,
      };

      setImages(position: number, desc: string, imgPath: string)
      {
          var imgtmp = new Image(position, {description: desc, img: imgPath});
          this.images.push(imgtmp);

          //test
      }
}