import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-LF159RF50",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class LF159RF50Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "LF159RF50";
    this._imagePath =
      "images/siemens/LF159RF50/01.jpg";
    this._headerText =
      "Priestor šetriaci stropný odsávač pár pre perfektné zabudovanie do stropu.";
    this._productPrice = 2409;
    this._productDesc = "../../../ProductDesc/LF159RF50.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/LF159RF50/01.jpg");    
      this.setImages(1, '', "images/siemens/LF159RF50/02.jpg");    
      this.setImages(2, '', "images/siemens/LF159RF50/03.jpg");
      this.setImages(3, '', "images/siemens/LF159RF50/04.jpg");
      this.setImages(4, '', "images/siemens/LF159RF50/05.jpg");
      this.setImages(5, '', "images/siemens/LF159RF50/06.jpg");
      this.setImages(6, '', "images/siemens/LF159RF50/07.jpg");
      this.setImages(7, '', "images/siemens/LF159RF50/08.jpg");
      this.setImages(8, '', "images/siemens/LF159RF50/09.jpg");
  }
}