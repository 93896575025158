import { Component, OnInit } from "@angular/core";
import {
  AccessibilityConfig,
  Action,
  ButtonEvent,
  ButtonsConfig,
  ButtonsStrategy,
  ButtonType,
  Description,
  DescriptionStrategy,
  DotsConfig,
  GridLayout,
  Image,
  ImageModalEvent,
  LineLayout,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  PreviewConfig
} from '@ks89/angular-modal-gallery';

@Component({
  selector: "app-productdetail",
  templateUrl: "./productdetail.component.html",
  styleUrls: ["./productdetail.component.css"]
})
export class ProductdetailComponent implements OnInit {
  _imagePath: string = "";
  _headerText: string = "";
  _productPrice: number = 0;
  _productDesc: string = "";
  _productId: string = "";
  constructor() {}

  images: Array<Image> = [];
  openImageModalRowDescription(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
  }

  private getCurrentIndexCustomLayout(
    image: Image,
    images: Array<Image>
  ): number {
    return image ? images.indexOf(image) : -1;
  }

  customDescription: Description = {
    strategy: DescriptionStrategy.HIDE_IF_EMPTY
  };

  setImages(position: number, desc: string, imgPath: string) {
    var imgtmp = new Image(position, { description: desc, img: imgPath });
    this.images.push(imgtmp);
  }

  ngOnInit() {}
}
