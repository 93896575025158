import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';
@Component({
  selector: 'app-blackline',
  templateUrl: '../gallerybase.html'
})
export class BlacklineComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(1, 'Resopal_3416', "images/Kitchen/Blackline/Resopal_3416_1.jpg");
    this.setImages(2, 'Resopal_3416', "images/Kitchen/Blackline/Resopal_3416_2.jpg");
    this.setImages(3, 'Resopal_3416', "images/Kitchen/Blackline/Resopal_3416.jpg");
    this.setImages(4, 'Montana_7710', "images/Kitchen/Blackline/Montana_7710_1.jpg");
    this.setImages(5, 'Opal H_5656', "images/Kitchen/Blackline/Opal H_5656_1.jpg");
    this.setImages(6, 'LED Venice', "images/Kitchen/Blackline/LED Venice.jpg");
    this.setImages(7, 'Linero', "images/Kitchen/Blackline/Linero.jpg");
    this.setImages(8, 'Posuvný systém', "images/Kitchen/Blackline/Posuvný systém.jpg");
    this.setImages(9, 'Ritzenhoff', "images/Kitchen/Blackline/Ritzenhoff.jpg");
    this.setImages(10, 'Sky_3461', "images/Kitchen/Blackline/Sky_3461.jpg");
    this.setImages(11, 'Top_1211', "images/Kitchen/Blackline/Top_1211.jpg");
        
    }
}
