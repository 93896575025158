import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-CI36BP01",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class CI36BP01Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "CI36BP01";
    this._imagePath =
      "images/siemens/CI36BP01/01.jpg";
    this._headerText =
      "iQ700 noFrost, Side by Side, zabudovateľná chladnička zabudovateľná kombinácia chladnička/mraznička, (hore dvojdverová)";
    this._productPrice = 4539;
    this._productDesc = "../../../ProductDesc/CI36BP01.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/CI36BP01/01.jpg");    
      this.setImages(1, '', "images/siemens/CI36BP01/02.jpg");    
      this.setImages(2, '', "images/siemens/CI36BP01/03.jpg");    
      this.setImages(3, '', "images/siemens/CI36BP01/04.jpg");    
      this.setImages(4, '', "images/siemens/CI36BP01/05.jpg");    
      this.setImages(5, '', "images/siemens/CI36BP01/06.jpg");    
      this.setImages(6, '', "images/siemens/CI36BP01/07.jpg");    
      this.setImages(7, '', "images/siemens/CI36BP01/08.jpg");    
      this.setImages(8, '', "images/siemens/CI36BP01/09.jpg");    
      this.setImages(9, '', "images/siemens/CI36BP01/10.jpg");    

  }
}
