import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-country',
  templateUrl: '../gallerybase.html'
})
export class CountryComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
     this.setImages(0, 'Basel_6930', "images/Kitchen/Country-house kitchens/Basel_6930_1.jpg");
     this.setImages(1, 'Bavaria_6850', "images/Kitchen/Country-house kitchens/Bavaria_6850_1.jpg");
     this.setImages(2, 'Bergen_6225', "images/Kitchen/Country-house kitchens/Bergen_6225_1.jpg");
     this.setImages(3, 'Breda_4340', "images/Kitchen/Country-house kitchens/Breda_4340_1.jpg");
     this.setImages(4, 'Chalet_5265', "images/Kitchen/Country-house kitchens/Chalet_5265_1.jpg");
     this.setImages(5, 'Contura_5899', "images/Kitchen/Country-house kitchens/Contura_5899_1.jpg");
     this.setImages(7, 'Feel-B_40299', "images/Kitchen/Country-house kitchens/Feel-B_40299_1.jpg");
     this.setImages(8, 'Frame_3310', "images/Kitchen/Country-house kitchens/Frame_3310_1.jpg");
     this.setImages(9, 'Frame_3328', "images/Kitchen/Country-house kitchens/Frame_3328_1.jpg");
     this.setImages(10, 'Frame_3367', "images/Kitchen/Country-house kitchens/Frame_3367_1.jpg");
     this.setImages(11, 'Gala_7010', "images/Kitchen/Country-house kitchens/Gala_7010_1.jpg");
     this.setImages(13, 'Luzern_6570', "images/Kitchen/Country-house kitchens/Luzern_6570_1.jpg");
     this.setImages(14, 'Milano_2850', "images/Kitchen/Country-house kitchens/Milano_2850_1.jpg");
     this.setImages(15, 'Roma S_3732', "images/Kitchen/Country-house kitchens/Roma S_3732_1.jpg");
     this.setImages(16, 'Royal_7650', "images/Kitchen/Country-house kitchens/Royal_7650_1.jpg");
     this.setImages(17, 'Sherwood_2036', "images/Kitchen/Country-house kitchens/Sherwood_2036_1.png");
     this.setImages(18, 'SHERWOOD_2041', "images/Kitchen/Country-house kitchens/SHERWOOD_2041_1.jpg");
     this.setImages(19, 'SMART_4041', "images/Kitchen/Country-house kitchens/SMART_4041_1.jpg");
     this.setImages(20, 'Sofie-B_4784', "images/Kitchen/Country-house kitchens/Sofie-B_4784_1.jpg");
     this.setImages(21, 'SOFIE-B_4799', "images/Kitchen/Country-house kitchens/SOFIE-B_4799_1.jpg");
     this.setImages(22, 'Top_1211', "images/Kitchen/Country-house kitchens/Top_1211_1.jpg");
     this.setImages(23, 'Village_4248', "images/Kitchen/Country-house kitchens/Village_4248_1.jpg");
     this.setImages(24, 'York S_5150', "images/Kitchen/Country-house kitchens/York S_5150_1.jpg");
     this.setImages(25, 'Top_1341', "images/Kitchen/Country-house kitchens/Top_1341_1.jpg"); 
     this.setImages(26, 'BREDA_4340_Kamin', "images/Kitchen/Country-house kitchens/BREDA_4340_Kamin.jpg"); 
     this.setImages(27, 'BREDA_4340_Schublade', "images/Kitchen/Country-house kitchens/BREDA_4340_Schublade.jpg"); 
     this.setImages(28, 'CASTELLO_7050_GALA_7010', "images/Kitchen/Country-house kitchens/CASTELLO_7050_GALA_7010.jpg"); 
     this.setImages(29, 'CHALET_5265', "images/Kitchen/Country-house kitchens/CHALET_5265.jpg"); 
     this.setImages(30, 'CONTURA_5899', "images/Kitchen/Country-house kitchens/CONTURA_5899.jpg"); 
     this.setImages(31, 'CONTURA_5899-B', "images/Kitchen/Country-house kitchens/CONTURA-B_5899.jpg"); 
     this.setImages(32, 'FRAME_3367', "images/Kitchen/Country-house kitchens/FRAME_3367.jpg"); 
     this.setImages(33, 'MONTANA_6850_DET', "images/Kitchen/Country-house kitchens/MONTANA_6850_DET_.jpg"); 
     this.setImages(34, 'MONTANA_6850_DETAIL', "images/Kitchen/Country-house kitchens/MONTANA_6850_DETAIL.jpg"); 
     this.setImages(35, 'MONTANA_6850', "images/Kitchen/Country-house kitchens/MONTANA_6850.jpg"); 
     this.setImages(36, 'ROYAL_7650_DET', "images/Kitchen/Country-house kitchens/ROYAL_7650_DET.jpg"); 
     this.setImages(37, 'ROYAL_7650', "images/Kitchen/Country-house kitchens/ROYAL_7650.jpg"); 
     this.setImages(38, 'ROYAL_XL_7650', "images/Kitchen/Country-house kitchens/ROYAL_XL_7650.jpg"); 
     this.setImages(39, 'SHERWOOD_2041_FRAME_3345', "images/Kitchen/Country-house kitchens/SHERWOOD_2041_FRAME_3345_.jpg"); 
     this.setImages(40, 'SHERWOOD_2041_FRAME_3345', "images/Kitchen/Country-house kitchens/SHERWOOD_2041_FRAME_3345.jpg"); 
     this.setImages(41, 'VILLAGE10-B_6825', "images/Kitchen/Country-house kitchens/VILLAGE10-B_6825.jpg");      
     this.setImages(42, 'TOP_1461_M', "images/Kitchen/Country-house kitchens/TOP_1461_M.jpg"); 
     this.setImages(43, 'TOP_1461_M', "images/Kitchen/Country-house kitchens/TOP_1461.jpg"); 
     this.setImages(44, 'VILLAGE_4784', "images/Kitchen/Country-house kitchens/VILLAGE_4784__.jpg"); 
     this.setImages(45, 'VILLAGE_4784', "images/Kitchen/Country-house kitchens/VILLAGE_4784_.jpg"); 
     this.setImages(46, 'VILLAGE_4784', "images/Kitchen/Country-house kitchens/VILLAGE_4784.jpg"); 
     this.setImages(47, 'FERRO_7315', "images/Kitchen/Country-house kitchens/FERRO_7315.jpg"); 
     this.setImages(48, 'Chalet_5265', "images/Kitchen/Country-house kitchens/Chalet_5265_1.jpg");
     
     
  }
}
