import { Component, OnInit } from '@angular/core';
import { GalleryBase } from '../gallerybase';

@Component({
  selector: 'app-ykitchen',
  templateUrl: '../gallerybase.html'
})
export class YkitchenComponent extends GalleryBase implements OnInit {

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setImages(0, 'EDITION_2706', "images/Kitchen/The Y kitchen/EDITION_2706_1.jpg");    
    this.setImages(1, 'EDITION_2706_EDITION_2726', "images/Kitchen/The Y kitchen/EDITION_2706_EDITION_2726.jpg");    
    this.setImages(2, 'Arte-B_3399', "images/Kitchen/The Y kitchen/XL_3399 Y Kitchen_1.jpg");       
    this.setImages(3, 'Arte-B_3399', "images/Kitchen/The Y kitchen/Arte-B_3399.jpg");       
    this.setImages(4, 'Stolový systém', "images/Kitchen/The Y kitchen/Stolový systém.jpg");       
  }
}
