import { Component, OnInit } from '@angular/core';
import {Router } from '@angular/router';

@Component({
  selector: 'app-kuchynebuttons',
  templateUrl: './kuchynebuttons.component.html',
  styleUrls: ['./kuchynebuttons.component.css']
})
export class KuchynebuttonsComponent implements OnInit {

  constructor(private _router: Router) {
   }

  ngOnInit() {
  }

  GetColor(url: string)
  {
    var result = 'normal';
    if (url === this._router.url)
    {
      result = "bold";
    }
    return result;;
  }
}
