import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-HB876G8B6",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class HB876G8B6Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "hm876g2b6";
    this._imagePath =
      "images/siemens/hb876g8b6/mcsa01934796-1341455-hb836gvb6-def.jpg";
    this._headerText =
      "Skráťte si čas varenia pomocou funkcie predohrevu a activeClean®. A majte rúru pod kontrolou odkiaľkoľvek vďaka Home Connect.";
    this._productPrice = 1849;
    this._productDesc = "../../../ProductDesc/HB876G8B6.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/hb876g8b6/01.jpg");    
      this.setImages(1, '', "images/siemens/hb876g8b6/02.jpg");    
      this.setImages(2, '', "images/siemens/hb876g8b6/03.jpg");
      this.setImages(3, '', "images/siemens/hb876g8b6/04.jpg");
      this.setImages(4, '', "images/siemens/hb876g8b6/05.jpg");
  }
}
