import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-BI830CNB1",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class BI830CNB1Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "BI830CNB1";
    this._imagePath =
      "images/siemens/bi830cnb1/mcsa02109794-bi830cnb1-waermeschubladen-siemens-pga1-def.jpg";
    this._headerText =
      "Ohrevná zásuvka je perfektná pre predohriatie riadu, tanierov, pre udržiavanie teploty jedla a pre jemné a pomalé varenie.";
    this._productPrice = 569;
    this._productDesc = "../../../ProductDesc/BI830CNB1.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/bi830cnb1/01.jpg");    
      this.setImages(1, '', "images/siemens/bi830cnb1/02.jpg");    
      this.setImages(2, '', "images/siemens/bi830cnb1/03.jpg");    
      this.setImages(3, '', "images/siemens/bi830cnb1/04.jpg");    
  }
}
