import { Component, OnInit } from "@angular/core";
import { ProductdetailComponent } from "../../productdetail/productdetail.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-BF834RGB1",
  templateUrl: "../../productdetail/productdetail.component.html",
  styleUrls: ["../../productdetail/productdetail.component.css"]
})
export class BF834RGB1Component extends ProductdetailComponent
  implements OnInit {
  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._productId = "BF834RGB1";
    this._imagePath =
      "images/siemens/bf834rgb1/mcsa01886140-1299376-bf834lgb1-def.jpg";
    this._headerText =
      "Zabudovateľná mikrovlnná rúra s výškou 38cm: pre rýchle rozmrazovanie, zohrievanie alebo prípravu jedla (v čo najkratšom možnom čase).";
    this._productPrice = 755;
    this._productDesc = "../../../ProductDesc/BF834RGB1.html";

    this.http
      .get(this._productDesc, { responseType: "text" })
      .subscribe(data => {
        document.getElementById("product-detail-desc").innerHTML = data;
      });

      this.setImages(0, '', "images/siemens/bf834rgb1/01.jpg");    
      this.setImages(1, '', "images/siemens/bf834rgb1/02.jpg");    
      this.setImages(2, '', "images/siemens/bf834rgb1/03.jpg");    
      this.setImages(3, '', "images/siemens/bf834rgb1/04.jpg");    
      this.setImages(4, '', "images/siemens/bf834rgb1/05.jpg");  
      this.setImages(5, '', "images/siemens/bf834rgb1/05.jpg");  
      this.setImages(6, '', "images/siemens/bf834rgb1/05.jpg"); 
  }
}
